import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Chapas.css';

const Chapas = () => {
    const [comp, setComp] = useState('');


    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Cincalum': return <Cincalum/>;
            case 'Color': return <Color/>;
            case 'Chapa lisa': return <ChapaLisa/>;
            case 'Chapa plastica': return <ChapaPlastica/>;
            case 'Junta compriband': return <Junta/>;
            case 'Perfil C negro y galvanizado': return <Perfil/>;
            default: return <Default/>
        }
    }



  return (
    <>
        <Banner title='Chapas y perfiles'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Chapas y perfiles</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Cincalum')} type='button'>Cincalum: acanalada, T101, T98</button>
                        <button onClick={()=> setComp('Color')} type='button'>Color: acanalada, T101, T98</button>
                        <button onClick={()=> setComp('Chapa lisa')} type='button'>Chapa lisa galvanizada y color</button>
                        <button onClick={()=> setComp('Chapa plastica')} type='button'>Chapa plástica y de policarbonato</button>
                        <button onClick={()=> setComp('Junta compriband')} type='button'>Junta compriband</button>
                        <button onClick={()=> setComp('Perfil C negro y galvanizado')} type='button'>Perfil C negro y galvanizado</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Chapas;

const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2Fprincipal.jpg?alt=media&token=c015f991-b27a-4c17-9e40-3ef51f67425e'/>
            <p>
                Son ideales para la fabricación de cerramientos, cubiertas residenciales, comerciales o industriales, tinglados, perfiles y aplicaciones de alto requerimiento. En LUMADI MADERAS encontraras toda la variedad de chapas en colores y medidas, ofreciendo el servicio de plegado de las mismas.            
            </p>
            <p>
                Se brinda el servicio de:
                <ul>
                    <li>Corte de chapa, de 0.50 en 0.50m hasta llegar a los 13m.</li>
                    <li>Zingueria: corte y plegado de chapas para terminaciones de techos, canaletas; lima hoya; lima tesa; cenefa; cumbrera; tubo.</li>
                </ul>
            </p>
        </>
    )
}


const Cincalum= ()=>{
    return(
        <>
            <h4 className='title-placas'>CINCALUM: ACANALADA, T101, T98</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Chapa de acero revestidos por el proceso de inmersión en caliente con una aleación de aluminio y cinc zinc (composición 55% Al y 45% Zn) con elevada resistencia a la corrosión. El revestimiento de Aluminio/Cinc ofrece una combinación de efecto barrera y protección galvánica que mejora su performance en aplicaciones de la construcción tanto en ambientes rurales como industriales y marinos. Este revestimiento Cincalum brinda excelente resistencia a la corrosión y a las altas temperaturas, superando de 2 a 6 veces la vida útil del galvanizado y ofreciendo mayor reflectividad.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F1v1.jpg?alt=media&token=12bac432-506d-408a-93ff-2f25178d413a' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <h4 style={{color: '#636363'}}>MODELO ACANALADA:</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F1v2.jpg?alt=media&token=9d575e08-2e53-4b81-90ba-4e0610f7be03' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <h4 style={{color: '#636363'}}>MODELO TRAPEZOIDAL:</h4>
            <div className='cards-placas-container space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F1v3.jpg?alt=media&token=db9874fc-3129-4845-97d6-7bc9c7dd4954' loading='lazy'/>
                        </div>
                    </div>
                    <h5>T101</h5>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F1v4.jpg?alt=media&token=af56bf27-d593-4630-bca4-f4934efa8d33' loading='lazy'/>
                        </div>
                    </div>
                    <h5>T98</h5>
                </div>
            </div>
        </>
    )
}


const Color= ()=>{
    return(
        <>
            <h4 className='title-placas'>COLOR: ACANALADA, T101, T98</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Las chapas prepintadas se obtienen a partir de un recubrimiento organico de poliésteres de ultima generación sobre un material base revestido. Estas chapas color ofrecen excelentes propiedades coomo su gran resistencia a la corrocion y su buena flexibilidad, además de su atractivo acabado en color. El revestimiento d epintura aplicado comprende un tratamiento anticorrosivo y una pintura de termiancion. Esta es una chapa para techos donde la estética, el congfort y la duración, se unen para darle a su hogar la calidez y seguridad que su techo necesita.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F2v1.jpg?alt=media&token=c5593bde-4957-44da-a985-d05e7b769520' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <h4 style={{color: '#636363'}}>Colores:</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F2v2.png?alt=media&token=65932fc9-d4ab-4713-866b-903cf311473d' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const ChapaLisa= ()=>{
    return(
        <>
            <h4 className='title-placas'>CHAPA LISA GALVANIZADA Y COLOR DE 1.22M X 2.44M</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>La chapa de acero galvanizado por inmersión en caliente es uno de los productos siderúrgicos de mayor desarrollo mundial. Se produce a partir de chapa de acero laminada en frío o en caliente, la cual se reviste en ambas caras con una capa de zinc. utiliza una línea de proceso contínuo que asegura alta adherencia y espesor homogéneo de recubrimiento. El producto, que combina las características de resistencia del acero con la durabilidad del zinc, es apto para fabricar piezas conformadas y sumamente resistente a la acción del medio ambiente.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F3v1.jpg?alt=media&token=4e11d64c-d425-4142-a5c4-aca9445782b9' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ChapaPlastica= ()=>{
    return(
        <>
            <h4 className='title-placas'>CHAPA PLÁSTICA Y DE POLICARBONATO</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Las chapas de poliéster reforzadas con fibras de vidrio (PRFV), ofrecen una inmejorable solución en todo tipo de cerramientos traslúcidos. Es importante mantener la alta transmisión de luz que inicialmente poseen, para lo cual hay que proteger adecuadamente la superficie de la chapa expuesta al medio externo.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F4v1.jpg?alt=media&token=f1934c93-b4a4-4a72-a08a-aacfec99bc79' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Junta = ()=>{
    return(
        <>
            <h4 className='title-placas'>JUNTA COMPRIBAND</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Se utiliza para sellar los extremos de las chapas. Modelos: acanalada, T101, T98, base y cumbrera.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F5v1.jpg?alt=media&token=533b5e74-de2c-4e30-a05f-f61ddae2ec44' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Perfil = ()=>{
    return(
        <>
            <h4 className='title-placas'>PERFIL C NEGRO Y GALVANIZADO</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Los perfiles abiertos en forma de “C”, por sus características técnicas, conformados en frío resultan la mejor opción cuando se requiere flexibilidad y rapidez. Se producen con chapa laminada en caliente o galvanizada a partir de flejes, en un proceso de conformado continuo que garantiza la exactitud de sus dimensiones, uniformidad y calidad en toda la superficie del producto. Pueden ser de color negro o galvanizado.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F6v1.jpg?alt=media&token=37e04379-24f7-441d-bb92-00fd550ef885' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'><span>Medidas:</span></p>
                    <p className='p-cards-comp'>80x40/50x15x1.6x12m</p>
                    <p className='p-cards-comp'>100x45/50x10x/15x1.6/2.0x12m</p>
                    <p className='p-cards-comp'>120x50x15x2.0x12m</p>
                    <br/>
                    <p className='p-cards-comp'>Consultar por otras medidas.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FChapas%2F6v2.jpg?alt=media&token=7abc4ca1-a579-4eec-8acf-dad207cae170' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}