import React, { useState, useEffect } from 'react';
import Banner from '../../components/Banner/Index';
import CardNew from '../../components/CardNew/Index'
import './Novedades.css';
import {Link} from 'react-router-dom';
import db from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';


const Novedades = () => {
  const [novedades, setNovedades] = useState([])

  useEffect(()=>{
    const obtenerDatos = async()=>{
        const docs = [];
        const datos = await getDocs(collection(db, 'novedades'));
        datos.forEach(doc =>{docs.push({...doc.data(), id: doc.id})});
        setNovedades(docs)
    }
    obtenerDatos();
  }, [])

  return (
    <>
      <Banner title='Novedades'/>
      <div className='cards-container'>
        <div className='cards'>
          {novedades.map(novedad => (
            <Link key={novedad.id} to={`/novedades/${novedad.id}`}>
              <CardNew novedad={novedad} edit={false}/>
          </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default Novedades;