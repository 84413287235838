import axios from 'axios';
import Papa from 'papaparse';

export const getProducts = async (URL) =>{
    return axios.get(URL, {responseType: 'blob'})
    .then( response =>{
        return new Promise((resolve, reject) =>{
            Papa.parse(response.data, {
                header:true,
                complete: (results) => {
                    return resolve(results.data)
                },
                error: error => reject(error.message)
            })
        })
    })
}

export const getLinks = async (URL) =>{
    return axios.get(URL, {responseType: 'blob'})
    .then( response =>{
        return new Promise((resolve, reject) =>{
            Papa.parse(response.data, {
                header:true,
                complete: (results) => {
                    return resolve(results.data[0])
                },
                error: error => reject(error.message)
            })
        })
    })
}