import React, {useState} from 'react';
import {AiFillCaretUp, AiFillCaretDown} from 'react-icons/ai';
import './ProductosMenu.css';
import {NavLink} from 'react-router-dom';

const ProductosMenu = () => {
    const [show, setShow] = useState(false);


  return (
    <>
        <button className='productos-menu' onClick={()=> setShow(!show)}>
            Productos
            {show ? (<AiFillCaretUp/>) : 
            (<AiFillCaretDown/>) }
        </button>
        <ul className={`menu-floating ${show ? 'show' : null}`}>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/precios'>Lista de precios</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/steel-framing'>Construcción en seco/Steel Framing</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/placas'>Placas</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/techos'>Techos</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/herrajes'>Herrajes</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/maderas'>Maderas nacionales e importadas</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/molduras'>Molduras</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/chapas-y-perfiles'>Chapas y perfiles</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/pisos-y-decks'>Pisos y Decks</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/herramientas'>Herramientas</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/aislacion'>Aislación</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/ferreteria'>Ferretería</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/yeso'>Yeso Proyectado</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/pinturas-y-accesorios'>Pinturas y accesorios</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/articulos-rurales'>Articulos rurales</NavLink></li>
            <li onClick={()=>setShow(false)}><NavLink to='/productos/calefaccion'>Calefacción, hornos y parrillas</NavLink></li>
        </ul>
    </>
  )
}

export default ProductosMenu;