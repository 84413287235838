import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Calefaccion.css';

const Calefaccion = () => {
    const [comp, setComp] = useState('');
    
    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Linea calefaccion - Tromen': return <CalefaccionTromen/>;
            case 'Linea calefaccion premier -Tromen': return <CalefaccionTromenPremier/>;
            case 'Linea calefaccion - Del sur': return <CalefaccionSur/>;
            case 'Linea gourmet - Tromen': return <Gourmet/>;
            case 'Linea gourmet a gas - Tromen': return <GourmetGas/>;
            case 'Accesorios y complementos - Tromen': return <Accesorios/>;
            default: return <Default/>
        }
    }

  return (
    <>
        <Banner title='Calefaccion, hornos y parrillas'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Calefaccion, hornos y parrillas</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Linea calefaccion - Tromen')} type='button'>Línea calefacción - Tromen</button>
                        <button onClick={()=> setComp('Linea calefaccion premier -Tromen')} type='button'>Línea calefacción premier -Tromen</button>
                        <button onClick={()=> setComp('Linea calefaccion - Del sur')} type='button'>Línea calefacción - Del sur</button>
                        <button onClick={()=> setComp('Linea gourmet - Tromen')} type='button'>Línea gourmet - Tromen</button>
                        <button onClick={()=> setComp('Linea gourmet a gas - Tromen')} type='button'>Línea gourmet a gas - Tromen</button>
                        <button onClick={()=> setComp('Accesorios y complementos - Tromen')} type='button'>Accesorios y complementos - Tromen</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Calefaccion;

const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2Fprincipal.jpg?alt=media&token=049554d5-d8ec-455a-8eba-3e5fa8df2467' loading='lazy'/>
            <p>
                Somos distribuidores exclusivos de la línea TROMEN. Gracias a su sistema de doble combustión, en donde la leña se quema dos veces, les permite obtener un relevante poder calórico con muy bajo consumo de leña en su cámara de fuego. De esta manera se logra reducir el consumo de la misma en un 40%, permitiendo en cierta forma regular la tala indiscriminada.            
            </p>
            <p>
                Al quemar y aprovechar casi el 100% de la leña, con el sistema de doble combustión, se logran liberar menos gases combustibles y partículas sólidas al ambiente, lo que ayuda a reducir la polución ambiental y la posibilidad de incendios. El combustible que usan los calefactores Tromen es la leña. La leña como fuente de energía es de tipo renovable, a diferencia del gas, por ejemplo.
            </p>
            <p>
                Estos productos han sido elaborados bajo estrictas normas de producción, con especial atención en la seguridad y el rendimiento durante su funcionamiento.
            </p>
            <p>
                Cuentan con innovadores diseños para su vivienda. Sólidos, herméticos, seguros y la característica de funcionar con muy bajo consumo, son algunos de las múltiples cualidades que posee cada equipo. Ingrese y descubra el calefactor a leña a la medida de su necesidad.
            </p>
        </>
    )
}


const CalefaccionTromen = ()=>{
    return(
        <>
            <h4 className='title-placas'>LÍNEA CALEFACCIÓN - TROMEN</h4>
            <p>Hay una opción para cada hogar en la Línea Calefacción de productos Tromen. Siendo la más completa de la marca, su familia de productos va desde equipos pequeños para ambientes reducidos, hasta calefactores de gran formato con derivadores de calor para brindar calor a varios ambientes de la casa.</p>
            <p>Los productos de la Línea Calefacción han sido elaborados bajo estrictas normas de producción, con especial atención en la seguridad y el rendimiento durante su funcionamiento. Todos los equipos de la Línea Calefacción cuentan con sistema de doble combustión, tecnología que les permite obtener un relevante poder calórico con muy bajo consumo de leña en su cámara de fuego.</p>
            <p>Encuentre en los calefactores Tromen innovadores diseños para su vivienda. Con dos modelos para empotrar, uno para esquina de ambiente y otro con doble frente, resulta la línea más completa para cada tipo de espacio a climatizar.</p>
            <p>Sólidos, herméticos, seguros y la características de funcionar con muy bajo consumo, son algunos de las múltiples cualidades que posee cada equipo de la Línea Calefacción de Tromen. Ingrese y descubra el calefactor a leña a la medida de su necesidad.</p>
            <div className='cards-placas-container space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v1.jpg?alt=media&token=2a4a947a-7f5a-4b42-baf3-7ca7aa91e156' loading='lazy'/>
                        </div>
                    </div>
                    <h5>HOGAR</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 76cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 72,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 42,3cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 55x42cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 100 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 93kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 13.500kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 65cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos superiores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> piso cenicero y cajón cenicero</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v2.jpg?alt=media&token=d5078a6e-3608-4322-b972-2e51f6abccc7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TR12001</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 65cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 86cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 46cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 43,8x25,9cmcm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 70 a 120 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 74,5kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 13.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 39cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> base, piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> un posterior</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v3.jpg?alt=media&token=c9c88598-a1a3-4199-af5c-b000b6df2719' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TR7001</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 49,4cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 78,4cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 47,1cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 26x32,6cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 90 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 85kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 8.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 32cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> uno posterior</p>
                </div>
                {/**********3*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v4.jpg?alt=media&token=c9789d00-5345-4ae2-81a5-7824d65b81ab' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PRISMA</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 66,5cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 78,4cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 50cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 26x32,6 cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 90 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 90kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 8.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 32cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> uno posterior</p>
                </div>
                {/*********4********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v5.jpg?alt=media&token=73c91952-d29a-48c8-a40d-19a363e531c1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GEO</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 54,9cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 78,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 47,1cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 26x32,6cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 90 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 85kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 8.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 32cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> uno posterior</p>
                </div>
                {/**********5*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v6.jpg?alt=media&token=bdfd5232-717b-4add-8dc8-1deb8162e5e8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CHALTÉN</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 56,1cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 84,2cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 42,7cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 37X33cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 100 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 85kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 9.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 40cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> uno posterior</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> parrilla superior y cenicero</p>
                </div>
                {/**********6*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v7.jpg?alt=media&token=7c04708e-831a-481c-b4d9-2d69dcdc79b8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CALAFATE</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 76cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 72,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 42,3cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 55x42cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 100 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 93kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 13.500kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 65cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos superiores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> parrilla superior y cenicero</p>
                </div>
                {/**********7*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F1v8.jpg?alt=media&token=4191d395-b409-4dd6-8a0c-7ef9e75012ae' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PATAGONIA</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 57,6cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 93,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 59,8cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 33X55cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 80 a 160 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 130kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 15.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 20cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 70cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos posteriores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> parrilla superior y cenicero</p>
                </div>
                {/*********8*******/}
            </div>
        </>
    )
}



const CalefaccionTromenPremier = ()=>{
    return(
        <>
            <h4 className='title-placas'>LÍNEA CALEFACCIÓN PREMIER - TROMEN</h4>
            <p>La evolución de los equipos a leña Tromen se traduce en la Línea Premier de la marca. Comprendida por los calefactores para empotrar de nivel premiun, son los equipos de calefacción desarrollados con especial atención en su refinado diseño y la simpleza en sus terminaciones.</p>
            <p>Dando lugar a la visión completa del fuego, los calefactores Roma, Madrid y Paris son los equipos de la marca con el frente completo en vitro cerámico Schott Robax, vidrio con cualidades que permite el contacto seguro en toda su superficie. Esta cualidad suma elegancia al frente de cada modelo en la Línea Premier.</p>
            <p>Entre los calefactores de la Línea Premier, se encuentran además de diseño, los equipos con mayor poder calórico de la marca, con potencias que llegan a los 27.000 kcal/h. Por eso, a las cualidades de los equipos de calefacción Tromen, los productos de la Línea Premier suman funcionalidad con su encendido rápido y bajo consumo con el doble templador para optimizar la combustión.</p>
            <p>Encuentre la unión perfecta entre elegancia y rendimiento en la Línea Premier con los mejores equipos de calefacción a leña Tromen.</p>
            <div className='cards-placas-container space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F2v1.jpg?alt=media&token=0ca1a93e-f349-40ad-addd-7d57d309ddeb' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MADRID</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 57,6cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 93,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 59,8cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 33x55cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 80 a 160 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 130kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 15.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 20cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 70cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos posteriores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> parrila superior y cenicero</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F2v2.jpg?alt=media&token=99c99ceb-a7ca-4045-a435-9f9905a30e97' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROMA</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 90cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 77,7cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 57cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 66x31,5cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 130 a 210 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 200kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 22.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 20cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 75cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos posteriores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> cenicero</p>
                    <p className='p-cards-comp'><span>Funcionalidad:</span> doble templador, esquís soportes, sistema de regulación de aire y encendido rápido</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F2v3.jpg?alt=media&token=791e791d-3042-4d4d-9fd7-5d310eb3d6be' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PARÍS</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 113cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 89,2cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 57cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 89x43cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 150 a 240 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 240kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 27.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 20cm</p>
                    <p className='p-cards-comp'><span>Leños:</span> 85cm</p>
                    <p className='p-cards-comp'><span>Material refractario:</span> piso y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> dos posteriores</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> cenicero</p>
                    <p className='p-cards-comp'><span>Funcionalidad:</span> doble templador, esquís soportes, sistema de regulación de aire y encendido rápido</p>
                </div>
                {/**********3*******/}
            </div>
        </>
    )
}



const CalefaccionSur = ()=>{
    return(
        <>
            <h4 className='title-placas'>LÍNEA CALEFACCIÓN - DEL SUR</h4>
            <p>Nuestros calefactores otorgan calor por convección durante la quema de leños, garantizando un ambiente sin humo gracias a su hermetismo.</p>
            <p>Con su puerta con vidrio vitrocerámico, resistente al choque de temperaturas, ofrece una visión integral del fuego. Disponible en dos versiones Andes y Tronador, según su potencia y el ambiente a climatizar, presentan un diseño que remarca el espíritu autóctono.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F3v1.jpg?alt=media&token=fda1e4cf-4207-4e4e-8978-a77220648fa1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANDES</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 40cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 75cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 44cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 60 a 90 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 75kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 8.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Ladrillos refractario:</span> base, fondo y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> un posterior</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> superficie para calentar</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F3v2.jpg?alt=media&token=e008b97e-09a1-477c-96bc-b72f208b9b3c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRONADOR</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 55cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 85cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 44cm</p>
                    <p className='p-cards-comp'><span>Ambiente a climatizar:</span> 70 a 120 m2</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 90kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 11.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Ladrillos refractario:</span> base, fondo y laterales</p>
                    <p className='p-cards-comp'><span>Derivados de calor:</span> un posterior</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> superficie para calentar</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F3v3.jpg?alt=media&token=16c8e899-be29-4357-88af-71da33bff1b6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>COPAHUE</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 76cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 72,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 42,3cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 55 a 42cm</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 89kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 13.500kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 15cm</p>
                    <p className='p-cards-comp'><span>Ladrillos refractario:</span> base y laterales</p>
                </div>
                {/**********3*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F3v4.jpg?alt=media&token=8cbc459e-8f94-481a-838f-3c41f0988c3d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ECO</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 35cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 65cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 44cm</p>
                    <p className='p-cards-comp'><span>Apertura de boca:</span> 24 a 17cm</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 65kg</p>
                    <p className='p-cards-comp'><span>Potencia:</span> 6.000kcal/h</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 10cm</p>
                    <p className='p-cards-comp'><span>Ladrillos refractario:</span> base y laterales</p>
                </div>
                {/**********4*******/}
            </div>
        </>
    )
}



const Gourmet = ()=>{
    return(
        <>
            <h4 className='title-placas'>LÍNEA GOURMET - TROMEN</h4>
            <p>La familia de productos Tromen destinados a la cocción de alimentos se agrupan en la Línea Gourmet de la marca. En ella se enmarcan todos los modelos de parrillas portables con diseños innovadores y los hornos de combustión envolvente TRH Tromen. El singular diseño y funcionamiento de los productos gourmet de la marca, descubren un nuevo perfil de sabores en las comidas elaboradas con un horno o parrilla Tromen.</p>
            <p>Los hornos TRH, con tres opciones de modelo según su lugar de instalación, resultan la opción de cocción que supera en su funcionamiento a las características de un horno de barro convencional. Su caja de fuego interna envuelve a la caja de cocción donde se preparan los alimentos, cualidad que le brinda a las comidas una cocción pareja y constante sin necesidad de rotar el alimento.</p>
            <p>Construidos en chapa de acero, piso interno con ladrillos refractarios, interior enlosado, puertas con vidrio templado y pirómetro para controlar temperatura, son algunas de las principales cualidades de los hornos TRH de Tromen. Ingrese aquí y conozca las tres alternativas de instalación para su hogar.</p>
            <p>Las parrillas de la Línea Gourmet definen una nueva categoría en el segmento de parrillas portables. Con atractivos diseños y versatilidad en sus funciones, los tres modelos de productos Tromen se caracterizan en el mercado por ampliar las posibilidades en el menú de los asados. Con accesorios cono el cajón calentador, la plancha asadora, la mesa extraíble y el fogonero portátil, las parrillas Tromen son los productos con mayores cualidades en su segmento, elevando el nivel en los sabores de las comidas e incrementando las opciones de alimentos para cocinar a la parrilla en casa. Descubrí en esta sección todos los modelos y sus posibilidades.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F4v1.jpg?alt=media&token=a5994d00-ca74-4d5b-800d-b3fbb2ef5a4c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRH PARA EMPOTRAR</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 56cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 76cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 77cm</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 100kg</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 10cm</p>
                    <p className='p-cards-comp'><span>Salida de vapores:</span> 10cm</p>
                    <p className='p-cards-comp'> Interior de cámara de cocción enlozada, bandeja de acero inoxidable y parrilla cromada, material refractario en el piso y cámara de combustión.</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F4v2.jpg?alt=media&token=d0cb41e1-6fb1-457b-888d-3f5c61ea18e6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRH PARA MESADA</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 60cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 77,6cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 80,5cm</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 110kg</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 10cm</p>
                    <p className='p-cards-comp'><span>Salida de vapores:</span> 10cm</p>
                    <p className='p-cards-comp'> Interior de cámara de cocción enlozada, bandeja de acero inoxidable y parrilla cromada, material refractario en el piso y cámara de combustión.</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F4v3.jpg?alt=media&token=b0a649d0-4d3c-4583-b026-66b929dda793' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRH CON BASE</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 60,1cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 146,9cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 89,2cm</p>
                    <p className='p-cards-comp'><span>Peso total:</span> 140kg</p>
                    <p className='p-cards-comp'><span>Salida de humo:</span> 10cm</p>
                    <p className='p-cards-comp'><span>Salida de vapores:</span> 10cm</p>
                    <p className='p-cards-comp'> Interior de cámara de cocción enlozada, bandeja de acero inoxidable y parrilla cromada, material refractario en el piso y cámara de combustión.</p>
                </div>
                {/**********3*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F4v4.jpg?alt=media&token=b74ba9dc-645d-4c5a-a161-1bccf2ab2ed3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PAMPEANA</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 107,8cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 106,6cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 47,9cm</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> plancha, fogonero y mesa extraíble</p>
                </div>
                {/**********4*******/}
            </div>
        </>
    )
}



const GourmetGas = ()=>{
    return(
        <>
            <h4 className='title-placas'>LÍNEA GOURMET A GAS - TROMEN</h4>
            <p>La familia de productos Tromen se ha agrandado, hemos incorporado la Línea Gourmet a Gas. En ella se enmarcan todos los modelos de parrillas portables con diseños innovadores y los hornos de combustión envolvente TRH a Gas Tromen. El singular diseño y funcionamiento de los productos gourmet de la marca, descubren un nuevo perfil de sabores en las comidas elaboradas con un horno o parrilla Tromen a gas. Los hornos TRH a Gas, con tres opciones de modelo según su lugar de instalación (mesada, empotrar o con base), resultan la opción de cocción que supera en su funcionamiento a las características de un horno de barro convencional. Su caja de fuego interna envuelve a la caja de cocción donde se preparan los alimentos, cualidad que le brinda a las comidas una cocción pareja y constante sin necesidad de rotar el alimento.</p>
            <p>Las parrillas de la Línea Gourmet a gas definen una nueva categoría en el segmento de parrillas portables. Con atractivos diseños y versatilidad en sus funciones, los dos modelos de productos Tromen se caracterizan en el mercado por ampliar las posibilidades en el menú de los asados. Con la facilidad del encendido y por sus cualidades, las parrillas a gas se pueden ubicar en lugares reducidos como por ejemplo balcones. Descubrí en esta sección todos los modelos y sus posibilidades.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F5v1.jpg?alt=media&token=1b3cfcbf-0361-4979-88e9-229a7e2149cd' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MALBEC</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 102,5cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 113cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 51,7cm</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> 3 quemadores individuales, encendido eléctrico y fácil limpieza</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F5v2.jpg?alt=media&token=dd442eb5-6fd7-4177-919f-f693d7d26a0f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CABERNET</h5>
                    <p className='p-cards-comp'><span>Ancho:</span> 76cm</p>
                    <p className='p-cards-comp'><span>Alto:</span> 113cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 51,7cm</p>
                    <p className='p-cards-comp'><span>Accesorios:</span> 2 quemadores individuales, encendido Piezoeléctrico</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F5v3.jpg?alt=media&token=cd26d2fc-85e0-41d0-b281-db378ce5b14a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANGUS GRANDE</h5>
                    <p className='p-cards-comp'> Nueva parrilla a Gas Tromen línea Gourmet.</p>
                    <p className='p-cards-comp'><span>Parrilla:</span> Alto: 35 cm; Ancho: 90 cm; Profundidad: 57 cm</p>
                    <p className='p-cards-comp'><span>Base:</span> Alto: 80 cm; Ancho: 105 cm; Profundidad: 56 cm</p>
                    <p className='p-cards-comp'><span>Contiene:</span> Tapa Curva, Protección interior en Acero Inoxidable, Frente pintado, Placa control en Acero Inoxidable y un Quemador multigas.</p>
                </div>
                {/**********3*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F5v4.jpg?alt=media&token=64537722-12d5-4ef0-9680-d0c6dfe881e8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANGUS CHICA</h5>
                    <p className='p-cards-comp'> Nueva parrilla a Gas Tromen línea Gourmet.</p>
                    <p className='p-cards-comp'><span>Parrilla:</span> Alto: 35 cm; Ancho: 49 cm; Profundidad: 57 cm</p>
                    <p className='p-cards-comp'><span>Base:</span> Alto: 80 cm; Ancho: 64 cm; Profundidad: 56 cm</p>
                    <p className='p-cards-comp'><span>Contiene:</span> Tapa Curva, Protección interior en Acero Inoxidable, Frente pintado, Placa control en Acero Inoxidable y un Quemador multigas.</p>
                </div>
                {/**********4*******/}
            </div>
        </>
    )
}



const Accesorios = ()=>{
    return(
        <>
            <h4 className='title-placas'>ACCESORIOS Y COMPLEMENTOS - TROMEN</h4>
            <p>La Línea Accesorios de Tromen es el complemento ideal tanto para la Línea Calefacción como para la Línea Gourmet. Realizados con la misma calidad técnica que caracteriza a los demás productos, los accesorios Tromen poseen un diseño moderno y funcional.</p>
            <p>Hay accesorios incluidos con algunos productos como el fogonero y tabla de corte de la parrilla Pampeana o las palas de madera exclusivas de los hornos.</p>
            <p>Hay accesorios adicionales que se pueden adquirir independientemente de los productos, por ejemplo el kit parrillero que consta de espeto, provoletera y disco de arado, y para la línea de Calefacción recomendamos el Kit del leñero en cruz que viene con la escobilla, el atizador y la pala. El brasero, el balde cenicero, el leñero ondulado completan la colección Calefacción.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v1.jpg?alt=media&token=85f2a62d-ee38-43c9-bc74-6f36b85cbd9a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>FUNDAS TROMEN</h5>
                    <p className='p-cards-comp'><span>Parrilla pampeana: </span> Ancho: 143 cm, Alto: 52 cm, Profundidad: 58 cm</p>
                    <p className='p-cards-comp'><span>Hornos TRH:</span> Ancho: 74 cm, Alto: 81 cm, Profundidad: 89 cm</p>
                    <p className='p-cards-comp'><span>Hornos TRHG criolla:</span> Ancho: 67 cm, Alto: 74 cm, Profundidad: 88 cm</p>
                    <p className='p-cards-comp'><span>Colores:</span> Rojo, verde, azul, gris y negro.</p>
                </div>
                {/*********1********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v2.jpg?alt=media&token=adf1085c-c850-4b5b-89c4-c204e8835534' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LEÑERO ONDULADO</h5>
                    <p className='p-cards-comp'><span>Largo toal:</span> 65cm</p>
                    <p className='p-cards-comp'><span>Altura total:</span> 7,8cm</p>
                    <p className='p-cards-comp'><span>Materiales:</span> Chapas de acero, patas en cedro brasileño.</p>
                </div>
                {/********2*********/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v3.jpg?alt=media&token=be4216c8-df59-4540-acf3-4623273d48db' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LEÑERO EN CRUZ KIT</h5>
                    <p className='p-cards-comp'><span>Leñero en cruz:</span> Ancho: 40,8 cm; Alto: 52,1 cm; Material: Chapa de acero.</p>
                    <p className='p-cards-comp'><span>Pala:</span> Ancho: 12,5 cm; Alto: 50 cm; Materiales: Placa de acero pintada y madera.</p>
                    <p className='p-cards-comp'><span>Cepillo:</span> Ancho: 12,5 cm; Alto: 45,5 cm; Materiales: Placa de acero pintada y madera.</p>
                    <p className='p-cards-comp'><span>Atizador:</span> Ancho: 9 cm; Alto: 50 cm; Materiales: Placa de acero pintada y madera.</p>
                </div>
                {/**********3*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v4.jpg?alt=media&token=c8091db3-8c74-4fd4-8357-b1c2492aeec7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BRASERO</h5>
                    <p className='p-cards-comp'><span>Alto:</span> 59,6cm</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 48,1cm</p>
                    <p className='p-cards-comp'><span>Profundidad:</span> 43,5cm</p>
                    <p className='p-cards-comp'><span>Material:</span> Acero pintado mate negro.</p>
                </div>
                {/**********4*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v5.jpg?alt=media&token=15e553e3-a7cc-4940-912d-57f1adc3de62' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BALDE CENICERO</h5>
                    <p className='p-cards-comp'><span>Alto:</span> 35cm</p>
                    <p className='p-cards-comp'><span>Diametro:</span> 30cm</p>
                    <p className='p-cards-comp'><span>Material:</span> Acero pintado mate negro.</p>
                </div>
                {/**********5*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v6.jpg?alt=media&token=34635b9a-94a2-4060-be46-e2beab371a45' loading='lazy'/>
                        </div>
                    </div>
                    <h5>DISCO DE ARADO</h5>
                    <p className='p-cards-comp'><span>Alto:</span> 22,5CM</p>
                    <p className='p-cards-comp'><span>Largo:</span> 48,7cm</p>
                    <p className='p-cards-comp'><span>Diametro:</span> 42cm</p>
                    <p className='p-cards-comp'><span>Espesor de chapa:</span> 0,16cm</p>
                    <p className='p-cards-comp'><span>Material:</span> Hierro ensolado.</p>
                </div>
                {/**********6*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v7.jpg?alt=media&token=ea54a028-cb28-41e5-ab6a-e47fde996365' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ESPETO CORRIDO</h5>
                    <p className='p-cards-comp'><span>Alto:</span> 57cm</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 3</p>
                    <p className='p-cards-comp'><span>Largo de espada:</span> 39cm</p>
                    <p className='p-cards-comp'><span>Material:</span> Acero inoxidable y madera</p>
                </div>
                {/**********7*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F6v8.jpg?alt=media&token=3f56f6d4-a79e-47b6-9117-7403577f2711' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PROVOLETERA</h5>
                    <p className='p-cards-comp'><span>Alto:</span> 4cm</p>
                    <p className='p-cards-comp'><span>Diametro:</span> 12,7</p>
                    <p className='p-cards-comp'><span>Material:</span> Acero inoxidable.</p>
                </div>
                {/**********8*******/}
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FCalefaccion%2F9v9.jpg?alt=media&token=3d448d8f-fdb0-4db2-a5e5-fa44017e0895' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LIMPIAVIDRIOS</h5>
                </div>
                {/**********9*******/}
            </div>
        </>
    )
}