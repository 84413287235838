import React, { useContext, useState } from 'react';
import './NewMaterial.css';
import {AiFillCaretDown, AiOutlineColumnWidth, AiOutlineColumnHeight} from 'react-icons/ai';
import {GoPrimitiveDot} from 'react-icons/go';
import { AppContext } from '../../Context/AppContext';

const NewMaterial = ({id, material, color, espesor, cantidad, ancho, alto, cantoDerecho, cantoIzquierdo, cantoInferior, cantoSuperior}) => {
    const [showMaterial, setShowMaterial] = useState(false);
    const {deleteMaterial} = useContext(AppContext);

    const ShowDetails = ()=>{setShowMaterial(!showMaterial)};

    const eliminar = ()=>{deleteMaterial(id)}

  return (
    <div className='newMaterial'>
        <button onClick={ShowDetails}><p>{material} color {color} {espesor}mm ({cantidad} unidades)</p><AiFillCaretDown/></button>
        <div className={`newMaterial-details ${showMaterial ? 'show' : null}`}>
            <div className='medidas'>
                <p><AiOutlineColumnWidth/>Ancho: <span>{ancho}mm</span></p>
                <p><AiOutlineColumnHeight/>Alto: <span>{alto}mm</span></p>
            </div>
            <div className='optimizador-divisor'>
                <hr/>
                <p className='subtitle-newMaterial'>Cantos rellenados</p>
            </div>
            <ul>
                {cantoDerecho !== '' ? <li><GoPrimitiveDot/>Derecho</li> : null}
                {cantoSuperior !== '' ? <li><GoPrimitiveDot/>Superior</li> : null}
                {cantoInferior !== '' ? <li><GoPrimitiveDot/>Inferior</li> : null}
                {cantoIzquierdo !== '' ? <li><GoPrimitiveDot/>Izquierdo</li> : null}
            </ul>
            {id ? (<button className='deleteButton' onClick={eliminar}>Eliminar Corte</button>) : null}
        </div>
    </div>
  )
}

export default NewMaterial;