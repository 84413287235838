import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Rurales.css';
import acindar from '../../../Images/marcas/12.png'

const Rurales = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Postes': return <Postes/>;
            case 'Varillas': return <Varillas/>;
            case 'Alambres': return <Alambres/>;
            case 'Torniquetas': return <Torniquetas/>;
            case 'Bisagras': return <Bisagras/>;
            case 'Tranqueras': return <Tranqueras/>;
            case 'Comederos': return <Comederos/>;
            case 'Bebederos': return <Bebederos/>;
            case 'Cepos': return <Cepos/>;
            case 'Mangas': return <Mangas/>;
            case 'Basculas': return <Basculas/>;
            case 'Cargador fijo y móvil': return <Cargadores/>;
            case 'Alambrados olimpicos': return <Alambrados/>;
            case 'Tubos y cabezales para alcantarillas': return <Tubos/>;
            case 'Tanques australianos y bebederos': return <Tanques/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Articulos Rurales'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Articulos Rurales</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Postes')} type='button'>Postes</button>
                        <button onClick={()=> setComp('Varillas')} type='button'>Varillas</button>
                        <button onClick={()=> setComp('Alambres')} type='button'>Alambres</button>
                        <button onClick={()=> setComp('Torniquetas')} type='button'>Torniquetas</button>
                        <button onClick={()=> setComp('Bisagras')} type='button'>Bisagras</button>
                        <button onClick={()=> setComp('Tranqueras')} type='button'>Tranqueras</button>
                        <button onClick={()=> setComp('Comederos')} type='button'>Comederos</button>
                        <button onClick={()=> setComp('Bebederos')} type='button'>Bebederos</button>
                        <button onClick={()=> setComp('Cepos')} type='button'>Cepos</button>
                        <button onClick={()=> setComp('Mangas')} type='button'>Mangas</button>
                        <button onClick={()=> setComp('Basculas')} type='button'>Básculas</button>
                        <button onClick={()=> setComp('Cargador fijo y móvil')} type='button'>Cargador fijo y movil</button>
                        <button onClick={()=> setComp('Alambrados olimpicos')} type='button'>Alambrados olímpicos</button>
                        <button onClick={()=> setComp('Tubos y cabezales para alcantarillas')} type='button'>Tubos y cabezales para alcantarillas</button>
                        <button onClick={()=> setComp('Tanques australianos y bebederos')} type='button'>Tanques australianos y bebederos</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Rurales;


const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2Fprincipal.jpg?alt=media&token=d237d785-86d9-4345-bbe2-1b690724496a' loading='lazy'/>
            <p>
                Somos una empresa dedicada a satisfacer las necesidades de nuestros clientes ganaderos y agropecuarios. Nos esforzamos por responder con celeridad, para ello contamos con un amplio stock y servicio de flete. Tenemos todo lo necesario para alambrar, realizar corrales, feedlots, cercos olímpicos, mangas, casillas de operar, bretes, básculas, comederos, bebederos y aguadas.            
            </p>
            <p>
                Contamos con premoldeados de hormigón y aguadas rurales en general.
            </p>
            <p>
            Toda la línea de productos de hormigón de primera calidad, todos estructurados con hierros de 6,8 y 10mm de diámetro y malla cima para su resistencia y durabilidad. Ofrecemos el servicio de entrega e instalación completa llave en mano, con una variada gama de productos y volúmenes.
            </p>
        </>
    )
}


const Postes = ()=>{
    return (
        <>
            <h4 className='title-placas'>POSTES</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F1v1.jpg?alt=media&token=3f150ce4-f27c-4d07-a0ee-3ed04bf1f558' loading='lazy'/>
                        </div>
                    </div>
                    <h5>POSTES DE EUCALIPTOS IMPREGNADOS CON SALES (CCA)</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 2.20m / 2.40m / 3.00m / 4.00m / 5.00m / 6.00m / 7.50m / 9.00m / 10.00m</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F1v2.jpg?alt=media&token=757028ce-db07-4a07-8532-0281b3ee73e4' loading='lazy'/>
                        </div>
                    </div>
                    <h5>POSTES DE QUEBRACHOS</h5>
                    <p className='p-cards-comp'><span>Medidas: </span>  2.20m / 2.40m / 2.60m / 3.00m / 3.50m / 4.00m</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F1v3.jpg?alt=media&token=5cbd9d31-073c-4875-a789-0b025145381a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>POSTES DE ITÍN</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 1.60m / 2.20m / 2.40m / 3.00m</p>
                </div>
            </div>
        </>
    )
}


const Varillas = ()=>{
    return (
        <>
            <h4 className='title-placas'>VARILLAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F2v1.png?alt=media&token=0b76caa7-8639-4c91-ba44-15305c902379' loading='lazy'/>
                        </div>
                    </div>
                    <h5>VARILLAS DE CURUPAY</h5>
                    <p className='p-cards-comp'><span>Medidas: </span>1 ½" x 2" X 1.20m / 1 ½" x 2" X 1.40m</p>
                </div>
            </div>
        </>
    )
}



const Alambres = ()=>{
    return (
        <>
            <h4 className='title-placas'>ALAMBRES</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <p>Contamos con gran variedad de alambre acindar</p>
                    <br/>
                    <p className='p-cards-comp'>17/15 x 1000mts “FORTIN”.</p>
                    <p className='p-cards-comp'>3.66mm x 500mts “CORRALERO”.</p>
                    <p className='p-cards-comp'>16/14 x 1000mts “PARA BOYERO”.</p>
                    <p className='p-cards-comp'>ALAMBRE DE ATAR Nº 9,10,11,12 Y 15.</p>
                    <p className='p-cards-comp'>ALAMBRE DE PUA 15/101 (4”) x 500mts “SUPER BAGUAL”.</p>
                    <p className='p-cards-comp'>16/101 (4”) x 100mts “BAGUALITO”; 16/101 (4”) x 500mts “BAGUAL”.</p>
                    <br/>
                    <p className='p-cards-comp'>TEJIDO ROMBOIDAL.</p>
                    <p className='p-cards-comp'>Medidas:</p>
                    <p className='p-cards-comp'>1.50 m x 50/63 x 14 x 10/15m</p>
                    <p className='p-cards-comp'>1.25m x 50/63 x 14 x 10/15m</p>
                    <p className='p-cards-comp'>1.80 x 50/63 x 12/14 x 10/15m</p>
                    <p className='p-cards-comp'>2.00m x 50/63 x 12/14 x 10/15m</p>
                    <br/>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src={acindar} loading='lazy'/>
                        </div>
                    </div>
                </div>

                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F3v2.jpg?alt=media&token=a6895b65-0aa1-484e-9922-8d0cc8074d03' loading='lazy'/>
                        </div>
                    </div>
                    <br/>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F3v3.png?alt=media&token=c00b3899-00d7-42cd-949f-8a276d4a486a' loading='lazy'/>
                        </div>
                    </div>
                    <br/>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F3v1.png?alt=media&token=d87c1b17-6c18-41ff-9bfc-87eb50aaeef5' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Torniquetas = ()=>{
    return (
        <>
            <h4 className='title-placas'>TORNIQUETAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F4v1.jpg?alt=media&token=8ead9043-7a7d-492a-988c-23b58a0efc8e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TORNIQUETAS</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F4v2.jpg?alt=media&token=da1fd3d9-e6d8-47ed-9f9e-f67c3ddc364d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TORNIQUETA DOBLE PARA POSTE</h5>
                </div>
            </div>
        </>
    )
}


const Bisagras = ()=>{
    return (
        <>
            <h4 className='title-placas'>BISAGRAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F5v1.png?alt=media&token=6139b9e5-0398-4e97-9199-62017fdb09df' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BISAGRAS</h5>
                </div>
            </div>
        </>
    )
}


const Tranqueras = ()=>{
    return (
        <>
            <h4 className='title-placas'>TRANQUERAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F6v1.jpg?alt=media&token=016abb2f-4f49-4f15-9962-0726ae04bf03' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRANQUERÓN DE HIERRO</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F6v2.jpg?alt=media&token=d3dc8818-e001-4c22-bcaa-b47e31b0bcf6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRANQUERON Y CONTRATRANQUERON CON CRIQUET</h5>
                  
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F6v3.jpg?alt=media&token=736f5c45-0ce0-4e87-bf34-0023e44e6a23' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRANQUERA DE TABLAS</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 1" x 4" / 1" x 6"</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F6v4.jpg?alt=media&token=87c8c851-f3c0-4444-886d-cb0d31a5ea16' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRANQUERA DE TIRANTES</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F6v5.jpg?alt=media&token=413717c4-ce1b-4828-b518-da2f77c3cbd3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TRANQUERAS ESPECIALES DE INGRESO</h5>
                    <p className='p-cards-comp'>Se realizan a medida.</p>
                </div>
            </div>
        </>
    )
}



const Comederos = ()=>{
    return (
        <>
            <h4 className='title-placas'>COMEDEROS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F7v1.png?alt=media&token=bef6cadd-5698-4438-9e93-92ca6dc7e152' loading='lazy'/>
                        </div>
                    </div>
                    <h5>COMEDERO DE MADERA DURA IMPREGNADA CON EMULSIÓN ASFÁLTICA PARA HACIENDA</h5>
                    <p className='p-cards-comp'>Fabricados estandar y a medida. Fácil manejo y traslado.</p>
                    <p className='p-cards-comp'><span>Medidas: </span>0.60m (base) x 0.90m (boca) x 0.74m (altura) x 0.50m (profundidad) x 3.60m / 4.10m.</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F7v2.png?alt=media&token=f68dfcc5-56f1-4f0c-9be7-c8ba4a99bf62' loading='lazy'/>
                        </div>
                    </div>
                    <h5>COMEDERO DE MADERA DURA NATURAL PARA HACIENDA</h5>
                    <p className='p-cards-comp'>Fabricados estandar y a medida. Fácil manejo y traslado.</p>
                    <p className='p-cards-comp'><span>Medidas: </span>0.60m (base) x 0.90m (boca) x 0.74m (altura) x 0.50m (profundidad) x 3.60m / 4.10m.</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F7v3.png?alt=media&token=0dc3a20f-29c1-4abf-91c9-8623c93a477f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>COMEDERO MEDIA CAÑA PARA HACIENDA CON O SIN PATAS</h5>
                </div>
            </div>
        </>
    )
}



const Bebederos = ()=>{
    return (
        <>
            <h4 className='title-placas'>BEBEDEROS</h4>
            <h4 style={{color: '#636363', fontSize: '20px'}}>BEBEDEROS PARA HACIENDA DE 500 Y 1000 LITROS CON PATAS Y CUBRE FLOTANTE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F8v1.png?alt=media&token=cbb73496-1e18-4168-af71-814fc7a0c72e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>1.000L</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F8v2.png?alt=media&token=1408476d-4724-4a04-8a68-f03ef97e294c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>500L</h5>
                </div>
            </div>
            <h4 style={{color: '#636363', fontSize: '20px'}}>BEBEDEROS PARA CERDOS DE 10, 20, 50, 120 Y 150 LITROS CON PATAS Y CUBRE FLOTANTE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F8v3.png?alt=media&token=a149509d-5aae-4683-8dc3-2958b8bd3dd9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>150L</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F8v4.png?alt=media&token=d48ea525-9f90-4a0c-af20-b7638db7d5a3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>20L</h5>
                </div>
            </div>
        </>
    )
}



const Cepos = ()=>{
    return (
        <>
            <h4 className='title-placas'>CEPOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F9v1.jpg?alt=media&token=9d463a83-bb2e-42cb-894c-ab691f263eab' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MODELO CE1 / SEMI AUTOMATICO A CREMALLERA</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F9v2.jpg?alt=media&token=3a8e53b1-8008-4fdc-b3fd-f03bf3a75a19' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MODELO CE2 / A PALANCA REFORZADA</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F9v3.jpg?alt=media&token=9c544cfb-46a9-43ee-a8b4-a2976ab42dd9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MODELO CE3 / LIVIANO</h5>
                </div>
            </div>
        </>
    )
}


const Mangas = ()=>{
    return (
        <>
            <h4 className='title-placas'>MANGAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F10v1.jpg?alt=media&token=0ca01084-abe7-4232-99fc-808050a01a50' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MANGA</h5>
                    <p className='p-cards-comp'>Manga de 7m de largo, alto 1.60cm. Cepo, puerta tranca ciega, mesa y escalón. Se fabrican otras medidas.</p>
                </div>
            </div>
        </>
    )
}



const Basculas = ()=>{
    return (
        <>
            <h4 className='title-placas'>BÁSCULAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F11v1.jpg?alt=media&token=2d82d9f8-5674-4b2b-91f5-1f8238e84f34' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BÁSCULA</h5>
                    <p className='p-cards-comp'>Capacidad 1.500kg / 3.000kg / 5.000kg. Con cabezal pesador con reglas graduadas de bronce, de fácil lectura directa.</p>
                </div>
            </div>
        </>
    )
}


const Cargadores = ()=>{
    return (
        <>
            <h4 className='title-placas'>CARGADOR FIJO Y MÓVIL</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F12v1.jpg?alt=media&token=42158c05-dcfe-47a6-ba62-8665be26a493' loading='lazy'/>
                        </div>
                    </div>
                    <h5>FIJO DE 3M Y 3.50M</h5>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F12v2.jpg?alt=media&token=51bf88d9-e49d-4ee4-9d43-93870c6aa779' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MÓVIL</h5>
                </div>
            </div>
        </>
    )
}


const Alambrados = ()=>{
    return (
        <>
            <h4 className='title-placas'>ALAMBRADOS OLÍMPICOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F13v1.jpg?alt=media&token=711134d2-3ac1-4086-a6cb-2adc045d2503' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ALAMBRADO OLÍMPICO</h5>
                </div>
            </div>
        </>
    )
}


const Tubos = ()=>{
    return (
        <>
            <h4 className='title-placas'>TUBOS Y CABEZALES PARA ALCANTARILLAS</h4>
            <p>De 30cm, 40cm, 50cm, 60cm, 80cm y 100cm de diámetro.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F14v1.png?alt=media&token=16084767-666b-4ef1-a8c4-9aea4e6add06' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Tanques = ()=>{
    return (
        <>
            <h4 className='title-placas'>TANQUES AUSTRALIANOS Y BEBEDEROS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FRurales%2F15v1.png?alt=media&token=d8a499a3-d3ea-4d2b-80e7-3f3b1db32f28' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TANQUE AUSTRALIANO</h5>
                </div>
            </div>
        </>
    )
}