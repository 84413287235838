import React from 'react';
import Banner from '../../../components/Banner/Index';
import './Herrajes.css';

const Herrajes = () => {

  return (
    <>
        <Banner title='Herrajes'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'> 

                </div>
            </div>
            <div className='content-products-container'>
              <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FHerrajes%2Fprincipal.jpg?alt=media&token=dba724bd-3f96-4c53-883e-6f15a27c61b7' loading='lazy'/>
              <p>
                Todos los herrajes del mundo en un solo lugar. Una empresa dedicada a la venta de herrajes para muebles y obras. Sistemas corredizos para muebles, placares y cajones; bisagras para muebles; elevadores para puertas de muebles; cubetas, tiradores y manijas; cerraduras para muebles; sistemas de apoyo.
              </p>
              <p>
                Brindamos soluciones para el cerramiento y la división de ambientes con sistemas corredizos tanto en vidrio como en madera de la más alta calidad y prestación.
              </p>
              <h5>Manejamos las principales marcas:</h5>
              <img className='marcas-herrajes' alt='marcas-principales' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FHerrajes%2F1v1.png?alt=media&token=8d4bedd4-5e84-475f-919e-7d65576cb573' loading='lazy'/>
            </div>
        </div>
    </>
  )
}

export default Herrajes;