import React from 'react';
import './PreServicios.css';

const PreServicios = () => {
  return (
    <div className='tienda-container' style={{backgroundColor: '#EDEBEB'}}>
            <div className='home-tittles'>
                <h3>Horarios de trabajo</h3>
                <hr/>
            </div>
        <div className='tienda-image-container'>    
            <div className='div-50'>
                <h4 style={{color: '#D70418', textAlign: 'center', margin: '0 0 5px 0', padding: '0'}}>Lunes a Viernes por la mañana</h4>
                <p style={{textAlign: 'center', margin: '0 0 15px 0', padding: '0'}}>De 08:00hs. a 12:00hs</p>
                <h4 style={{color: '#D70418', textAlign: 'center', margin: '0 0 5px 0', padding: '0'}}>Lunes por la tarde</h4>
                <p style={{textAlign: 'center', margin: '0 0 15px 0', padding: '0'}}>De 15:00hs. a 19:00hs</p>
            </div>
            <div className='div-50'>
            <h4 style={{color: '#D70418', textAlign: 'center', margin: '0 0 5px 0', padding: '0'}}>Martes a Viernes por la tarde</h4>
                <p style={{textAlign: 'center', margin: '0 0 15px 0', padding: '0'}}>De 14:00hs. a 19:00hs</p>
                <h4 style={{color: '#D70418', textAlign: 'center', margin: '0 0 5px 0', padding: '0'}}>Sabados y Domingos</h4>
                <p style={{textAlign: 'center', margin: '0 0 15px 0', padding: '0'}}>Cerrado</p>
            </div>
        </div>
    </div>
  )
}

export default PreServicios;
