import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Techos.css';
import kunstler from './../../../Images/marcas/24.png';

const Techos = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Tiranterias': return <Tiranterias/>;
            case 'Machimbres': return <Machimbres/>;
            case 'Clavaderas': return <Clavaderas/>;
            case 'Cenefas de madera y fibrocemento': return <Cenefas/>;
            case 'Herrajes y fijacion para tiranterias': return <Herrajes/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Techos'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Techos</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Tiranterias')} type='button'>Tiranterías</button>
                        <button onClick={()=> setComp('Machimbres')} type='button'>Machimbres</button>
                        <button onClick={()=> setComp('Clavaderas')} type='button'>Clavaderas</button>
                        <button onClick={()=> setComp('Cenefas de madera y fibrocemento')} type='button'>Cenefas de madera y fibrocemento</button>
                        <button onClick={()=> setComp('Herrajes y fijacion para tiranterias')} type='button'>Herrajes y fijación para tiranterías</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Techos;


const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='techos' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2Fprincipal%20.jpg?alt=media&token=81a52d9d-2abc-4bd7-bc9a-3f436dd8a6c0' loading='lazy'/>
            <p>
            Los techos de madera permiten desarrollar construcciones eficientes, resistentes, estéticas y económicas. Algunas de sus ventajas son la rapidez de su montaje, mantenimiento simple, liviano, térmico, secos; permite lograr diseños modernos y clásicos sin perder su elegancia.            </p>
            <p>
            Aquí, el profesional encontrará todos los materiales necesarios para realizar su obra como: tirantería, machimbre, clavaderas, impregnantes, insecticidas y lustres, aislación hidrofuga y térmica, coberturas, zingueria, cenefas de madera y fibrocemento, junta compriband, herrajes y fijaciones.            </p>
            <p>
            En LUMADI MADERAS encontrara desde el tirante hasta el clavo, brindándole al cliente soluciones en todo su proyecto.            </p>
        </>
    )
}


const Tiranterias = ()=>{
    return(
        <>
            <h4 className='title-placas'>Tiranterias</h4>
            <p>Indicada para estructuras de techo, envigados y diversas aplicaciones en construcciones no a la vista. Se eliminan los problemas de deformaciones y agrietamientos en las diversas aplicaciones en construcción. Es una madera estable y liviana, retención de fijaciones, excelentes resultados al ser pintada, barnizada o si se requiere perforar, lijar o moldurar. Se puede pegar con todo tipo de adhesivos para madera.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v1.png?alt=media&token=ebaf8856-702d-4497-8508-b92bfc9bd8d4' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERÍA COMPENSADA DE PINO ELLIOTIS SIN NUDO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span>  2"x6" / 3"x6" / 3"x8" / 3"x9" / 3"x10" / 3"x12"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 12 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v2.png?alt=media&token=35936813-d68f-41ea-b01b-6080328476c7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERÍA COMPENSADA DE SALIGNA/EUCA SIN NUDO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 2"x6" / 3"x6" / 3"x8" / 3"x9" / 3"x10" / 3"x12"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 12 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v3.png?alt=media&token=59c12b69-71a9-4aa1-9cef-c8e04b5a01a9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERÍA MACIZA CEPILLADA DE PINO ELLIOTIS</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 2"x6" / 3"x6" / 3"x8" / 3"x10"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 4.88 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v4.png?alt=media&token=97616039-40c7-4952-9ac8-f4b17979b980' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERÍA DE PINO ELLIOTIS BILAMINADA Y TRILAMINADA CON NUDO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 2"x6" / 3"x6" / 3"x8"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 12 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v5.png?alt=media&token=593398b4-bb84-4c9f-bea7-1bdd2c32a77c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERÍA DE PINO PARANÁ</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 2"x6" / 3"x6" / 3"x8" / 3"x10" / 3"x12"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 5.49 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F1v6.png?alt=media&token=a7531a70-8477-4bfb-a912-7cf9dd804087' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TIRANTERIA DE MADERA NATIVA DURA Y SEMIDURA, CEPILLADA</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 2"x6" / 3"x6" / 3"x8" / 3"x10" / 3"x12"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 5.49 metros..</p>
                </div>
            </div>
        </>
    )
}



const Machimbres = ()=>{
    return(
        <>
            <h4 className='title-placas'>MACHIMBRES</h4>
            <p>Pieza de madera moldurada cuyos laterales tienen un perfecto encastre. Perfil de madera de Pino Tadea o Euca/Saligna, seca en cámara, especialmente diseñado para ser usado en tabiquería y revestimiento de muros en aplicaciones interiores. Perfilada y de dimensiones exactas al estar cepillada en sus cuatro caras.</p>
            <p>Ideal para uso en revestimiento, tabiquería y diversas aplicaciones en la construcción donde se requiere precisión de las dimensiones o una terminación de calidad para instalación a la vista. Las ventajas son que es una madera estable, excelentes resultados al ser pintada teñida o barnizada.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F2v1.png?alt=media&token=b08e1f02-10d7-46ea-a4b3-8f74ad3d208f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MACHIMBRE DE PINO ELLIOTIS 1° CALIDAD SECO DE HORNO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x4" - 3/4"x5" - 1"x6"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 2.44 / 3.05 / 3.66 / 4.28 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F2v2.png?alt=media&token=698f8c90-8a36-47cf-9448-2d800c02dfb9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MACHIMBRE DE PINO ELLIOTIS LIBRE DE NUDO "TECNO"</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x4" - 3/4"x5" - 1"x6"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 2.44 / 3.05 / 3.66 / 4.28 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F2v3.png?alt=media&token=806400d5-f81d-461e-947c-26fc91a37567' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MACHIMBRE DE SALIGNA/EUCA PREMIUM SIN NUDO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x4" - 3/4"x5" - 1"x6"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 2.44 / 3.05 / 3.66 / 4.28 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F2v4.png?alt=media&token=80eb2342-3c5f-402e-bc49-5cc0ffaa8cb8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MACHIMBRE DE PINO ELLIOTIS SELECT 1° CALIDAD SECO DE HORNO</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x4" - 3/4"x5" - 1"x6"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 2.44 / 3.05 / 3.66 / 4.28 metros.</p>
                </div>
            </div>
        </>
    )
}


const Clavaderas = ()=>{
    return(
        <>
            <h4 className='title-placas'>CLAVADERAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F3v1.png?alt=media&token=46b6dd20-8cec-46e9-97b0-4fd1617cc15a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLAVADERA DE PINO ELLIOTIS CEPILLADA</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x2" - 1"x2" - 2"x2"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 4.27 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F3v2.png?alt=media&token=2a8a6f4a-9434-4e4e-8af9-631b8b39930c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLAVADERA DE PINO ELLIOTIS SIN CEPILLAR</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1/2"x2" - 1"x2" - 2"x2"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 4.27 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F3v3.png?alt=media&token=42060500-ca3a-4e62-8227-71a86faccb0e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLAVADERA DE SALIGNA/EUCA SIN CEPILLAR</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1"x2" - 2"x2"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 4.27 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F3v4.png?alt=media&token=909fc375-ccd9-4795-9302-31b7c787362f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLAVADERA DE PINO PARANÁ CEPILLADA</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1"x2" - 2"x2"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 4.27 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F3v5.png?alt=media&token=4cfd1c2e-37bf-4e40-aa83-86d02a4f26d2' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLAVADERA DE PINO PARANÁ SIN CEPILLAR</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 1"x2" - 2"x2"</p>
                    <p className='p-cards-comp'><span>Largos:</span> 4.27 metros.</p>
                </div>
            </div>
        </>
    )
}



const Cenefas = ()=>{
    return(
        <>
            <h4 className='title-placas'>CENEFAS</h4>
            <p>Son los elementos que cumplen la función de terminación y cierre en el borde de los faldones. En estos puntos hay que cuidar que el escurrimiento del agua de lluvia y de posibles filtraciones se realice por lugares adecuados para no perjudicar la madera de la estructura y evitar su paso al interior de la construcción. La cenefa puede ser frontal o lateral.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F4v1.png?alt=media&token=23be96f8-3009-4ad0-850d-f0d46c2b5667' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CENEFA DE SALIGNA CEPILLADA 4 CARAS</h5>
                    <p className='p-cards-comp'><span>Secciones:</span>  1"x4" / 1"x6" / 1"x8"</p>
                    <p className='p-cards-comp'><span>Largos:</span> hasta 3.96 metros.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F4v2.png?alt=media&token=69b00b9d-1452-41c1-b9ff-238141a70402' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CENEFA DE FIBROCEMENTO SAIDING</h5>
                    <p className='p-cards-comp'><span>Secciones:</span> 6 y 8 mm de espesor.</p>
                    <p className='p-cards-comp'><span>Medidas:</span> 0.20 x 3.66 metros.</p>
                </div>
            </div>
        </>
    )
}


const Herrajes = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <img src={kunstler} alt='kunstler'/></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>HERRAJES Y FIJACIÓN PARA TIRANTERÍA</h4>
            <p>Esta herrajería posibilita el armado de todo tipo de estructuras de madera, ya sea unidas entre sí o bien ancladas a paredes, pisos o vigas de hormigón. La construcción de cielorrasos, entrepisos, escaleras, barandas de balcones, construcción de techos, cabriadas, stands, pérgolas, y cualquier otro tipo de estructura de madera, reemplazando al sistema convencional de encastre, el cual debe ser realizado por carpinteros altamente especializados para lograr un óptimo rendimiento estructural y una excelente terminación.</p>
            <p>Este sistema no sólo agiliza y facilita la construcción de estructuras, sino que debido a su prolija terminación el Herraje puede quedar a la vista convirtiéndose en un toque decorativo.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v1.jpg?alt=media&token=f64168a8-6d1a-4560-8389-1b48d2c9824e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AP3X6</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v2.jpg?alt=media&token=33373b1c-7c6e-4653-b97a-679b056a4246' loading='lazy'/>
                        </div>
                    </div>
                    <h5>APC3X6</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v3.jpg?alt=media&token=26aff021-a6fc-4a96-a1cc-9b230005572b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>APC6</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v4.jpg?alt=media&token=a16cb948-3d44-4c27-acfd-5b0686353519' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AP7</h5>
                </div>
                {/**********************
                 * *********4**********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v5.jpg?alt=media&token=80a2261a-4dc9-4e7c-9357-888eec830098' loading='lazy'/>
                        </div>
                    </div>
                    <h5>APCE(1)</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v6.jpg?alt=media&token=d0dfab4f-9e8e-49ad-b878-32827da5144f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>APCE-66</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v7.jpg?alt=media&token=e8607be7-25aa-4257-bc5c-c201534194d6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>APH(EJEMP8)</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v8.jpg?alt=media&token=974672a0-9abc-46bd-b938-dce1400bab53' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CSID90(3)</h5>
                </div>
                {/**********************
                 * *********8*********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v9.jpg?alt=media&token=53a1862e-aa74-4e3c-ab6b-94a90e93b48a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ECOESCALERA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v10.jpg?alt=media&token=23602151-c515-4485-a52c-a1653cd7678c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ENTREPISO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v11.jpg?alt=media&token=5d4358b4-0650-457d-833b-ceaaefea44f9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ESCALERALOFT</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v12.jpg?alt=media&token=27079b67-ed98-4ece-b881-7127d9b38a53' loading='lazy'/>
                        </div>
                    </div>
                    <h5>IMG_3922(2)</h5>
                </div>
                {/**********************
                 * *********12*********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v13.jpg?alt=media&token=03a1305c-0b31-4b15-b10a-a74c2d1e3555' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MN-12</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v14.jpg?alt=media&token=69123e96-29e8-4c32-ba3a-7171478da9f8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>NUDOSID(1)</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v15.jpg?alt=media&token=277143be-82aa-42fb-b04c-da56f6831c1b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PATIO CON PERGOLA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v16.jpg?alt=media&token=87bb6804-88b1-4e97-b157-8ba4658650d7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERGOLA CON TRONCOS</h5>
                </div>
                {/**********************
                 * *********16*********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v17.jpg?alt=media&token=fa46898d-4bd3-4dd8-a9a7-98a11d30ccaf' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PU-6</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v18.jpg?alt=media&token=127e25a2-8bda-4459-a349-5379c5eae7aa' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PUP</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v19.jpg?alt=media&token=02c4cffe-8e9a-4d60-9adb-af4a856b0125' loading='lazy'/>
                        </div>
                    </div>
                    <h5>QUINCHO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v20.jpg?alt=media&token=31ce402e-9bfd-4184-8979-a4301630c987' loading='lazy'/>
                        </div>
                    </div>
                    <h5>QUINCHO</h5>
                </div>
                {/**********************
                 * *********20*********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v21.jpg?alt=media&token=dbba8485-d5c1-4f9b-88c7-00caea717edc' loading='lazy'/>
                        </div>
                    </div>
                    <h5>RL90-25</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v22.jpg?alt=media&token=ab75b875-bc79-4e1f-8037-2bd544b7b97f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SCLH3045-36</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v23.jpg?alt=media&token=436d73eb-585a-4d7f-b82c-6137c022687f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SCLT3045-36</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v24.jpg?alt=media&token=9e37a943-e900-4f2a-a61b-2a4a64af7336' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SI-30 45-4</h5>
                </div>
                {/**********************
                 * ********24***********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v25.jpg?alt=media&token=22988a12-10f3-401f-a3c2-d234af37ad37' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SI90-4</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v26.jpg?alt=media&token=f970fcac-140b-4033-ba3d-84235827e268' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SIMW45-4</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v27.png?alt=media&token=c7d3cb3d-b0c7-433d-8e96-1b99f129c348' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SLDG-70</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v28.jpg?alt=media&token=f5d691a4-4119-4102-bf01-737727439b41' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SLDR-3</h5>
                </div>
                {/**********************
                 * *********28**********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v29.png?alt=media&token=765b2644-ce9b-4f78-aa59-70b803a940fe' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SOLDADURA PATAS</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v30.jpg?alt=media&token=c02036e9-8dda-4ac0-b896-849e39349e39' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SP</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v31.jpg?alt=media&token=9901a7b0-d84c-44f8-a32e-fee078a34444' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SP</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v32.jpg?alt=media&token=449bb22c-2481-4798-aa50-4eb53bbffd80' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SPM (STEEL)</h5>
                </div>
                {/**********************
                 * ********28**********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v33.jpg?alt=media&token=dec857a7-308b-442c-9557-ac219ca4f226' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TALLER13</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v34.jpg?alt=media&token=aaa007a6-3198-418f-95f9-4401462d7fc1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TALLER24</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v35.jpg?alt=media&token=ae2ffbdd-bf8a-4530-b1ea-cd4a1ebb2e2f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>UC30-6</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v36.jpg?alt=media&token=a2e93631-bada-4e07-9f06-ef6ac68029fc' loading='lazy'/>
                        </div>
                    </div>
                    <h5>UCG60</h5>
                </div>
                {/**********************
                 * *********32*********
                 **********************/}
                 <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v37.jpg?alt=media&token=a9e68e35-484e-47d5-9ee6-c9a372206cba' loading='lazy'/>
                        </div>
                    </div>
                    <h5>UTP</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FTechos%2F5v38.jpg?alt=media&token=446efeb5-3ebd-4c2a-a45f-82b82b472985' loading='lazy'/>
                        </div>
                    </div>
                    <h5>WP_20141024_16_33_43_PRO</h5>
                </div>

            </div>
        </>
    )
}