import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Maderas.css';

const Maderas = () => {
    const [comp, setComp] = useState('');


    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Maderas duras': return <Duras/>;
            case 'Maderas semiduras': return <SemiDuras/>;
            case 'Nacionales blandas': return <Blandas/>;
            default: return <Banner title='Default'/>
        }
    }

  return (
    <>
        <Banner title='Maderas'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Maderas</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Maderas duras')} type='button'>Maderas duras</button>
                        <button onClick={()=> setComp('Maderas semiduras')} type='button'>Maderas semiduras</button>
                        <button onClick={()=> setComp('Nacionales blandas')} type='button'>Nacionales blandas</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Maderas;


const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2Fprincipal.jpg?alt=media&token=c143d568-2b00-4bb4-b37b-5912c43f4a43' loading='lazy'/>
            <p>
                En Lumadi Maderas contamos con gran variedad de maderas nacionales, en sus dimensiones más variadas, para poder satisfacer las necesidades de todos nuestros clientes.            
            </p>
            <p>
                El machimbre, los tirantes, vigas, cenefas, listones y cabios, son muy usados como material para la construcción de techos gracias a sus propiedades como aislante térmico y acústico. Pino Elliotis, Pino Paraná, Eucaliptus, Saligna.
            </p>
            <p>
                <h5>Maderas Blandas:</h5>
                Son las de los árboles de rápido crecimiento, normalmente de las coníferas, árboles con hoja de forma de aguja. Son fáciles de trabajar y de colores generalmente muy claros.
            </p>
            <p>
                <h5>Maderas Duras:</h5>
                Son las de los árboles de lento crecimiento y de hoja caduca. Suelen ser aceitosas y se usan en muebles, en construcciones resistentes, en suelos de parqué, para algunas herramientas, etc.
            </p>
        </>
    )
}


const Duras = ()=>{
    return(
        <>
            <h4 className='title-placas'>NACIONALES DURAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v1.png?alt=media&token=591fdb3b-437f-4a34-8df3-287b48b94bc3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANCHICO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Pérgolas/Rurales.</p>     
                </div>
                
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v2.png?alt=media&token=abf88f4c-3afb-4c4b-9a78-6b9cc3d179f7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CAÑA FISTOLA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Pérgolas/Techos.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v3.png?alt=media&token=42b785c0-3bde-4df1-8ca9-aaa60fa928b3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GRAPIAS</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Marcos de puertas/Umbrales.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v4.png?alt=media&token=28405040-3024-475e-90ab-83b126b32adb' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GUAYUVIRA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Pérgolas/Techos/Barras/Decks.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v5.png?alt=media&token=2982b689-b276-4413-ae8a-99f7c08106cf' loading='lazy'/>
                        </div>
                    </div>
                    <h5>INCIENSO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Muebles/Marcos.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v6.png?alt=media&token=dbcec3e0-1d82-46b0-975a-68c825008680' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PETIRIBY</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Marcos para puertas/Muebles.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v7.png?alt=media&token=6bcc4cd6-72a8-42e4-84f4-ecf742e20667' loading='lazy'/>
                        </div>
                    </div>
                    <h5>RABO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Rurales/Balanzas.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F1v8.png?alt=media&token=0f4c6263-7828-4415-9251-f29145c395b2' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROSTRATA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Rurales.</p>     
                </div>
            </div>
        </>
    )
} 


const SemiDuras = ()=>{
    return(
        <>
            <h4 className='title-placas'>NACIONALES SEMIDURAS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v1.png?alt=media&token=f63dfa3a-1700-4fab-97d6-454dba851751' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CEDRO MISIONERO LARGO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Puertas/Muebles.</p>     
                </div>
                
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v2.png?alt=media&token=42aac033-8536-4f4e-8e47-0916100515e1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GUAYCA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Tablones/Andamios.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v3.png?alt=media&token=e6abfb29-6af2-44b3-bc17-71b06faf4030' loading='lazy'/>
                        </div>
                    </div>
                    <h5>KIRI</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Muebles/Cenefas.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v4.png?alt=media&token=c9679840-47de-433d-9985-b0e6d1bac6b1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LAUREL</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Tablones/Andamios.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v5.png?alt=media&token=c419ea7b-78cc-47a7-99cf-1543f490ead8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MERMELERO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Tirantería para techos/Muebles/Tableros.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v6.png?alt=media&token=5a3ba5a5-2b54-4469-81f5-97057d6aaeac' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PARAISO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Carpintería general.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v7.png?alt=media&token=ebb60c8b-9b3c-4c3f-8a31-f0aa1b5cc6ac' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SALIGNA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Comederos para hacienda/Usos varios.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F2v8.png?alt=media&token=0ac03ddc-dfbb-46f3-a3c8-69839df59456' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ZOITA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Tirantería para techos.</p>     
                </div>
            </div>
        </>
    )
} 



const Blandas = ()=>{
    return(
        <>
            <h4 className='title-placas'>NACIONALES BLANDAS</h4>
            <div className='cards-placas-container'>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F3v1.png?alt=media&token=52e82a80-3234-475a-a034-6dc2c664877a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ÁLAMO</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/2"/4"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Armado de puertas/Placas.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F3v2.png?alt=media&token=2798a304-612e-42c9-bc32-c360e40b2d17' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PINO PARANÁ</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"/4"/5"/6"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Tirantería para techos.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F3v3.png?alt=media&token=9aa7a565-0e1a-4187-94e3-dc1876fe4d41' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SALIGNA CEPILLADA A CRAS</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1" X 6"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Muebles/Cenefas.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F3v4.png?alt=media&token=54b872e4-9f32-4257-a8c4-5006da82ebd6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SALIGNA OREADA</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> 1"x4"/6" - 3"x3"</p>
                    <p className='p-cards-comp'><span>Uso:</span> Puntuales.</p>     
                </div>

                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMaderas%2F3v5.png?alt=media&token=7801756e-701b-4a25-b24d-600e066884fb' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PINO ELLIOTIS CEPILLADO Y SIN CEPILLAR</h5>

                    <p className='p-cards-comp'><span>Medidas:</span> Espesores de 1" / 2" / 3" - Dimensiones de 3" / 4" / 5" / 6" / 8" x 2.44 / 3.05 / 3.66 / 4.28 / 4.88</p>
                    <p className='p-cards-comp'><span>Uso:</span> Cenefas/Construcción/Albañilería/Encofrados/Techos de madera.</p>     
                </div>
            </div>
        </>
    )
} 