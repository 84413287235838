import React, { useState, useContext } from 'react';
import {AuthContext} from '../../Auth/useAuth';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import './Login.css';

const Login = () => {
    const auth = getAuth();


    const {login, logout} = useContext(AuthContext);
    let dateLogin = localStorage.getItem('date');
    if(dateLogin !== null && (Date.now() - parseInt(dateLogin) < 172800000)){
      login();
      localStorage.setItem('date', Date.now());
    }
    else{
      logout();
      localStorage.removeItem('date')
    }


    //let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);


    const changeEmail = (e)=> setEmail(e.target.value)

    const changePassword = (e)=> setPassword(e.target.value)


    const checkLog = ()=>{
      signInWithEmailAndPassword(auth, email, password).then( () =>{
        login();
        localStorage.setItem('date', Date.now());
      }).catch( err => setError(true))
        
    }


  return (
    <div className='login-container'>
        <div className='login-content'>
            <h5>Ingresar</h5>
            <form>
                <input onChange={changeEmail} placeholder='E-mail' type='email'/>
                <input onChange={changePassword} placeholder='Contraseña' type='password'/>
                <p className={`error ${error ? 'show' : null}`}>El nombre de usuario o la contraseña está mal.</p>
                <button onClick={checkLog} type='button'>Ingresar</button>
            </form>
        </div>
    </div>
  )
}

export default Login;
