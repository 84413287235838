import React, {useState, useContext} from 'react';
import { AppContext } from '../../Context/AppContext';
import {MdOutlineCheckBoxOutlineBlank, MdCheckBox, MdComment} from 'react-icons/md';
import {BsTrash} from 'react-icons/bs';
import db from '../../firebase/firebase';
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import './ItemTable.css'
import Download from '../download/Index';

const ItemTable = ({documento, check}) => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);

  const {setChangeDo} = useContext(AppContext);

  const deleteDocumento = async() =>{
    setChangeDo(documento.id);
    deleteDoc(doc(db, 'optimizador', documento.id));
    setShowModal(false);
    alert('Se ha eliminado el documento, actualiza la página para ver los cambios')
  }

  const changeStatus = async()=>{
    if(documento.status === 'false'){ 
      await setDoc(doc(db, "optimizador", documento.id), {...documento, status: 'true'});
      setChangeDo(`${documento.id} - ${documento.status}`);
    }
    else {
      await setDoc(doc(db, "optimizador", documento.id), {...documento, status: 'false'});
      setChangeDo(`${documento.id} - ${documento.status}`);
    }
  }

  const showNow = () =>{
    setShowModal(!showModal)
  }

  return (
    <>
        <tr>
            <td>{(documento.date).split('-').reverse().join('/')}</td>
            <td>{documento.client.name}</td>
            <td>{documento.client.email}</td>
            <td>{documento.client.phone}</td>
            <td>
                <button onClick={()=> setShow(!show)} type='button'>
                  <MdComment/>
                  <p className={`comment-doc ${show ? 'show' : null}`}>Comentarios: {documento.comentario || 'No hay'}</p>
                </button>
                {check ? (
                  <button onClick={changeStatus} id={'false'}><MdCheckBox  id={'false'}/></button>) : (
                  <button onClick={changeStatus} id={'true'}><MdOutlineCheckBoxOutlineBlank  id={'true'}/></button>
                  )
                }
                <Download client={documento.client} cortes={documento.cortes}/>
                <button onClick={showNow}><BsTrash/></button>
                <div className={`modal-container ${showModal ? 'show' : null}`}>
                  <div className='modal'>
                    <p>¿Estás seguro de eliminar el documento?</p>
                    <div className='buttons-modal'> 
                      <button onClick={showNow}>Cancelar</button>
                      <button onClick={deleteDocumento}>Eliminar</button>
                    </div>
                  </div>
                </div>
            </td>
        </tr>
    </>
  )
}

export default ItemTable