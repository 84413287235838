import React, { useState, useContext } from 'react';
import {AppContext} from '../../Context/AppContext';
import { NavLink } from 'react-router-dom';
import {BsTrash} from 'react-icons/bs';
import './CardNew.css';
import db from '../../firebase/firebase';
import { doc, deleteDoc } from "firebase/firestore";

const CardNew = ({novedad, edit}) => {
  const [showModal, setShowModal] = useState(false)
  const {setDeleNov} = useContext(AppContext);

  const deleteNovedad = async() =>{
    setDeleNov(novedad.id);
    await deleteDoc(doc(db, 'novedades', novedad.id));
    setShowModal(false);
    alert('Se ha eliminado la novedad, actualiza la página para ver');
  }

  const show = () =>{
    setShowModal(!showModal)
  }

  return (
    <div className='card'>
      <div className='card-img'>
        <img src={novedad.img} alt='img novedad'/>
      </div>
      <div className='card-content'>
        <div className='card-title'>
          <h5>{novedad.title}<span>  -  {novedad.date}</span></h5>
        </div>
        <p className='card-description'>
          {novedad.shortDescription.slice(0, 109)}...
        </p>
      </div>
      { edit ? 
      (<div className='card-buttons'>
        <NavLink to={`/novedades/edit/${novedad.id}`}>Editar Novedad</NavLink>
        <button onClick={show}><BsTrash/></button>
        <div className={`modal-container ${showModal ? 'show' : null}`}>
          <div className='modal'>
            <p>¿Estás seguro de eliminar la novedad?</p>
            <div className='buttons-modal'> 
              <button onClick={show}>No</button>
              <button onClick={deleteNovedad}>Si, eliminar</button>
            </div>
          </div>
        </div>
      </div>) : null
      }
    </div>
  )
}

export default CardNew;