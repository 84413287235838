import React from 'react';
import {HiDownload} from 'react-icons/hi';
import * as XLSL from 'xlsx';


const Download = ({client, cortes}) => {

    let processData = ()=>{
        let doc = [];
        for (const e of cortes) {
          let obj = {Altura: e.ancho, Ancho: e.alto, Cantidad: e.cantidad, Material: `${e.material} ${e.color} ${e.espesor}mm` , Espesor: e.espesor, Referencia: e.referencia, 'Direccion de la fibra': '' ,'': '' , 'Cinta izquierda': e.cantoIzquierdo, ' ': '' , 'Cinta derecha': e.cantoDerecho, '  ': '' , 'Cinta Superior': e.cantoSuperior, '    ': '' , 'Cinta Inferior': e.cantoInferior};
          doc.push(obj);
        }
        let wb = XLSL.utils.book_new();
        let ws = XLSL.utils.json_to_sheet(doc);

        XLSL.utils.book_append_sheet(wb, ws, "Hoja1");

        XLSL.writeFile(wb, `Documento-${client.name}.xlsx`)
    }


  return (
    <>
      <button onClick={processData}><HiDownload/></button>
    </>
  )
}

export default Download