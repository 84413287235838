import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import arauco from './../../../Images/marcas/13.png';
import rehau from './../../../Images/marcas/8.png';
import './Placas.css';

const Placas = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Faplac melaminas': return <Melaminas/>;
            case 'Faplac fondos': return <Fondos/>;
            case 'Trupan MDF': return <MDF/>;
            case 'MDP': return <MDP/>;
            case 'OSB': return <OSB/>;
            case 'Terciado fenolico': return <Terciado/>;
            case 'MDF enchapado': return <Enchapado/>;
            case 'Tapacantos melaminicos y de PVC': return <Tapacantos/>;
            case 'Revestimientos': return <Revestimientos/>;
            case 'Tableros': return <Tableros/>;
            case 'Formica': return <Formica/>;
            case 'Pizzaron': return <Pizarron/>;
            case 'Paner ranulado expositor': return <Paner/>;
            default: return <Default/>
        }
    }

  return (
    <>
        <Banner title='Placas'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Placas</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Faplac melaminas')} type='button'>Faplac melaminas</button>
                        <button onClick={()=> setComp('Faplac fondos')} type='button'>Faplac fondos</button>
                        <button onClick={()=> setComp('Trupan MDF')} type='button'>Trupan MDF</button>
                        <button onClick={()=> setComp('MDP')} type='button'>MDP</button>
                        <button onClick={()=> setComp('OSB')} type='button'>OSB</button>
                        <button onClick={()=> setComp('Terciado fenolico')} type='button'>Terciado fenólico</button>
                        <button onClick={()=> setComp('MDF enchapado')} type='button'>MDF enchapado</button>
                        <button onClick={()=> setComp('Tapacantos melaminicos y de PVC')} type='button'>Tapacandos melamínicos y de PVC</button>
                        <button onClick={()=> setComp('Revestimientos')} type='button'>Revestimientos</button>
                        <button onClick={()=> setComp('Tableros')} type='button'>Tableros</button>
                        <button onClick={()=> setComp('Formica')} type='button'>Formica</button>
                        <button onClick={()=> setComp('Pizzaron')} type='button'>Pizarrón</button>
                        <button onClick={()=> setComp('Paner ranulado expositor')} type='button'>Paner ranulado expositor</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Placas;


const Default = ()=>{
    return (
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://www.faplaconline.com.ar/home/' rel='noreferrer' target='_blank'><img src={arauco} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2Fprincipal.jpg?alt=media&token=c06eb350-cadb-4233-afbf-b417c5b5582e'/>
            <p>
                Somos distribuidores exclusivos de FAPLAC. Contamos con toda la variedad en melaninas. Ofreciendo toda la línea de tableros de MDF y aglomerados (MDP).
            </p>
            <p>
                Son tableros revestidos en ambas caras con papeles decorativos impregnados en resinas melaminicas. Caracterizados por la alta resistencia a la abrasión, desgaste y manchado, de fácil limpieza. Son el principal componente en todo tipo de muebles. Caracterizados por sus diseños lisos y texturados. Además brinda terminaciones perfectas en revestimiento de pareces, tabiques, divisorios y cielorrasos.
            </p>
        </>
    )
}


const Melaminas = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://www.faplaconline.com.ar/home/' rel='noreferrer' target='_blank'><img src={arauco} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>FAPLAC MELAMINAS</h4>
            <p>FAPLAC Melamina es un tablero de MDP o MDF recubierto en ambas caras con papel decorativo y resinas melamínicas. El sustrato MDF son tableros de fibras de densidad media obtenida de troncos de Pino Radiata. Sus superficies son lisas y homogéneas. El sustrato MDP son tableros de partículas de densidad media, formado por tres capas de madera aglutinadas, en que las superiores e inferiores presentan una selección de partículas finas y de mayor densidad mientras que la capa central presenta una densidad menor con partículas de mayor tamaño.</p>
            <br/>
            <p>FAPLAC Melamina se inspira en los estilos de vida contemporáneos, simples, minimalistas y en la búsqueda de espacios que se conectan con la naturaleza y el diseño, ofreciendo nuevas soluciones creativas para el diseño de muebles y de ambientes.</p>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>18mm x 1.83m x 2.75m. - 15mm x 1.83m x 2.75m.</p>
            <br/>
            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>URBAN</h4>
            <div className='cards-placas-container space'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v1.png?alt=media&token=ab0d10f5-231d-4dcc-b163-8cfb3973c051' loading='lazy'/>
                        </div>
                    </div>
                    <h5>HOME</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v2.png?alt=media&token=26752753-ded5-4ee3-a2d9-9d3c34a17c5e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>STREET</h5>
                </div>
            </div>

            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>NÓRDICA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v3.jpg?alt=media&token=12aacf62-c994-4f79-8a7a-83a92162085e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>HELSINKI</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v4.jpg?alt=media&token=e1dc83a1-67ed-4779-9e48-70d86dc9c3ff' loading='lazy'/>
                        </div>
                    </div>
                    <h5>OLMO FINLANDÉS</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v5.jpg?alt=media&token=c8bb49a5-a263-47e1-94dd-828df1d5ed9c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BÁLTICO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v6.jpg?alt=media&token=07cbf9d1-fa54-4571-93ef-5bad132ef5c9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROBLE ESCANDINAVO</h5>
                </div>
            </div>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v7.jpg?alt=media&token=eff7dfd0-6270-4e84-abd5-7bd942fbcea1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TEKA OSLO</h5>
                </div>
            </div>
            
            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>HILADOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v8.jpg?alt=media&token=b6544903-f8d2-441a-ba9d-e6012cd51100' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SEDA GIORNO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v9.jpg?alt=media&token=4cb2eb2a-6300-476f-98c3-cb0a712423ea' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SEDA NOTTE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v10.jpg?alt=media&token=1cf0adb8-8a91-499f-9bfa-4e0d682312eb' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LINO TERRA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v11.jpg?alt=media&token=2ec2fb14-253e-435b-a1ea-db05446f9ece' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LINO CHIARO</h5>
                </div>
            </div>

            <div className='cards-placas-container space'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v12.jpg?alt=media&token=95634103-d801-431e-ab38-6a2f35252bde' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LINO BLANCO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v13.jpg?alt=media&token=41f12715-f40e-4bab-9cec-0426428867f0' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LINO NEGRO</h5>
                </div>
            </div>

            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>NATURE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v14.jpg?alt=media&token=a3089d10-966a-4154-bc38-09d7af2e6828' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CARVALHO ASERRADO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v15.jpg?alt=media&token=9a5d164f-0447-4aa1-a139-2db085e52ad7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>VENEZIA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v16.jpg?alt=media&token=d5e93ac9-fbd5-426d-bb4e-85f6c9e8741b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>NOCCE MILANO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v17.jpg?alt=media&token=6a0ae9d2-ac2a-479f-8b9e-61396f95d7f4' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LINOSA CINZA</h5>
                </div>
            </div>
            {/**************
             * ***** 4 ******
             ***************/}
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v18.jpg?alt=media&token=bf1abc6d-9c79-4b13-b540-7d5a1a81f749' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CARVALHO MEZZO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v19.jpg?alt=media&token=9d9579c7-3a39-4982-85b4-8a987af66af4' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TEKA ARTICO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v20.jpg?alt=media&token=cbde4aed-c807-4814-86fa-fd6f772a618a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TERRARUM</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v21.jpg?alt=media&token=b5c8bd8e-0b71-4a66-9dee-85e06c3d43ea' loading='lazy'/>
                        </div>
                    </div>
                    <h5>NOGAL TERRACOTA</h5>
                </div>
            </div>
            {/**************
             * ***** 8 ******
             ***************/}
             <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v22.jpg?alt=media&token=c4132be5-b531-46c0-87d5-d8bf0a0f4ec8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BLANCO NATURE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v23.jpg?alt=media&token=9593e764-d630-4f47-9663-423020ef9efd' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROBLE DAKAR NATURE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v24.jpg?alt=media&token=c82b2645-920a-4a82-8877-20bc6a66340d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CEDRO NATURE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v25.jpg?alt=media&token=c4f12145-8fd8-458f-bfd2-6f3e07a90312' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROBLE AMERICANO NATURE</h5>
                </div>
            </div>
            
            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>METALIC</h4>
            <div className='cards-placas-container space'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v26.jpg?alt=media&token=827ac612-5d23-435e-b10d-2fd3a5f10d4b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LITIO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v27.jpg?alt=media&token=406ec14e-095b-43dc-92ba-15722afd8d99' loading='lazy'/>
                        </div>
                    </div>
                    <h5>COBRE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v28.jpg?alt=media&token=f246aeca-d2e6-4307-89fc-2e02888590ab' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TITANIO</h5>
                </div>
            </div>
                        
            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>CLÁSICA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v29.jpg?alt=media&token=34d09030-729e-4b45-b1d3-d3b9a3b846e1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ABEDUL</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v30.jpg?alt=media&token=b9916ca1-2acc-403f-a8c0-06b0b8b75094' loading='lazy'/>
                        </div>
                    </div>
                    <h5>HAYA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2Froble-espanol.jpg?alt=media&token=dec9c434-3d37-462b-86b2-bcf2bbd95c14' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROBLE ESPAÑOL</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v31.jpg?alt=media&token=94d004e1-9c5d-4f04-af27-9d6c3796f5b7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERAL</h5>
                </div>
            </div>
            {/************
             * ***********
             * **********/}
             <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v32.jpg?alt=media&token=b9e81b3f-9957-4493-85a4-1ff78dcfa775' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CEREZO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v33.jpg?alt=media&token=00d250ea-e201-4504-9409-c81767cee9aa' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CEDRO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v34.jpg?alt=media&token=13bb28a1-73a2-4839-8e3d-eb3a3b03bcd9' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TANGÁNICA TABACO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v35.jpg?alt=media&token=9c78c20c-2956-4da8-9251-05596ae119db' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROBLE DAKAR</h5>
                </div>
            </div>
            {/************
             * ***********
             * **********/}
             <div className='cards-placas-container space'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v36.jpg?alt=media&token=57fe16d3-7374-42bd-905c-42e8a48066ed' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ÉBANO NEGRO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v37.jpg?alt=media&token=1126af3d-86d3-4d22-b2c0-d904c83cf605' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MAPLE</h5>
                </div>
            </div>

            <h4 style={{padding: '15px 15px', color: '#B54442', fontSize: '20px', backgroundColor: '#F2DEDE'}}>LISOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v38.jpg?alt=media&token=c1db8ed6-f5a3-490a-b48b-f25434cf6e24' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AMARILLO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v39.jpg?alt=media&token=41f10384-af01-4120-838b-fa7062ddc410' loading='lazy'/>
                        </div>
                    </div>
                    <h5>VERDE</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v40.jpg?alt=media&token=bc96bc51-312c-40c5-abdb-cd337b2c5aa8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LILA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v41.jpg?alt=media&token=b5940198-ac6a-4727-a75f-0e70c849c0e3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROJO</h5>
                </div>
            </div>
            {/************
             * ***********
             * **********/}
             <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v42.jpg?alt=media&token=be4443d1-6281-4113-8615-2dccf2ee2f83' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BLANCO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v43.jpg?alt=media&token=8613ce46-9391-4c4a-8098-f671519d863b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AZUL LAGO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v44.jpg?alt=media&token=461f23ec-23ea-4b35-b889-2c6d2975b65d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ALMENDRA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v45.jpg?alt=media&token=1bea6e08-77fb-4958-8a3b-76ecd1062fac' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CENIZA</h5>
                </div>
            </div>
            {/************
             * ***********
             * **********/}
             <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v46.jpg?alt=media&token=391cb202-84cd-4fef-800f-764a1d0b240f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GRIS HUMO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v47.jpg?alt=media&token=379e9466-6301-4da4-9bf9-efd46cc994c6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>GRAFITO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v48.jpg?alt=media&token=88e43b69-4a99-4b1f-914c-db1cfd2b2c54' loading='lazy'/>
                        </div>
                    </div>
                    <h5>NEGRO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F1v49.jpg?alt=media&token=71c5873d-f276-4913-9a7d-fba6b7d8d9ac' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ALUMINO</h5>
                </div>
            </div>
        </>
    )
}



const Fondos = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://www.faplaconline.com.ar/home/' rel='noreferrer' target='_blank'><img src={arauco} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>FAPLAC FONDOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Somos distribuidores de FAPLAC FONDOS; que son tableros de MDF de 3mm de espesor, revestidos en una de sus caras con finish foil, lámina celulósica decorativa tratada con un barniz especial de terminación.</p>
                    <br/>
                    <p>Se utiliza principalmente como fondo para muebles de oficina, muebles de cocina, fondos de cajones, placards, etc., siendo el complemento ideal para nuestro producto FAPLAC Melamina.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Espesor: </span> 3mm.</p>
                    <p className='p-cards-comp'><span>Dimensión: </span> 1.83m x 2.60m.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F2v1.jpg?alt=media&token=fd8e701c-092c-447a-b818-4705c969fb46' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>Y de FAPLAC REVESTIMIENTOS; que son tableros de MDF de 5.5mm de espesor, melaminizado en una de sus caras con papel decorativo siendo el mismo diseño y acabado que los tableros FAPLAC Melamina. Se utiliza principalmente como fondos para muebles de cocina, placards, cajones, revestimientos, etc. Es el complemento ideal para nuestro producto FAPLAC Melamina.</p>
                    <br/>
                    <p>Tienen mayor resistencia estructural ya que presentan casi el doble de espesor que los fondos convencionales generando muebles más fuertes. Mayor resistencia a la abrasión, desgaste y manchado, ya que son fondos melamínicos y no de finish foil. Idéntico diseño y acabado de los tableros FAPLAC Melamina dado que comparten el mismo proceso de fabricación. Permite hacer revestimientos resistentes al desgaste a un bajo costo por m2.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Espesor: </span> 5.5mm.</p>
                    <p className='p-cards-comp'><span>Dimensión: </span> 1.83m x 2.75m.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F2v2.jpg?alt=media&token=b8bb355b-bfda-43f9-a132-27cd2a202385' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const MDF = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://www.faplaconline.com.ar/home/' rel='noreferrer' target='_blank'><img src={arauco} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>TRUPAN MDF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>Tablero de fibras de madera de densidad media, ideal para ser usado tanto en la industria del mueble como en la construcción. Se puede moldurar, perfilar, perforar y rebajar. Es un tablero producido con fibras de madera, proveniente de plantaciones manejadas bajo el concepto de una continua y permanente reforestación.</p>
                    <p>Es fácil de trabajar, versátil, excelente trabajabilidad, cortes precisos y perfectos. Tiene superficies suaves y homogéneas. Excelente retención de fijaciones (caras y cantos). Sustrato ideal para lograr alto nivel de terminación (pintado, laqueado y enchapado).</p>
                    <p>Se utiliza en mueblería, molduras, arquitectura de interior</p>
                    <br/>
                    <p className='p-cards-comp'><span>Espesor: </span> en 3 y 5.5mm de espesor, 1.83m x 2.60m</p>
                    <p className='p-cards-comp'>En 9, 12, 15 y 18mm de espesor, 1.83m x 2.75m</p>
                    <p className='p-cards-comp'><span>Dimensión: </span> 1.83m x 2.60m (medida estándar).</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F3v1.png?alt=media&token=41d527ad-3794-475c-904e-c08b48ff3426' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const MDP = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <img src={arauco} alt='knauf'/></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>MDP</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>Tablero producido con partículas de madera, proveniente de plantaciones manejadas bajo el concepto de una continua y permanente reforestación. FAPLAC MDP se utiliza como base para la fabricación de muebles, revestimientos, tabiques y pisos. Tiene optimas características de trabajabilidad. Superficie perfectamente lisa, apta para ser revestida o pintada. Puede ser impreso, pintado, teñido o laqueado en forma manual o industrial. Consume menos sellador y pintura que otros tableros.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Espesor: </span>15mm y 18mm.</p>
                    <p className='p-cards-comp'><span>Dimensión: </span> 1.83m x 2.75m.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F4v1.png?alt=media&token=bb9deb83-18cc-4aa8-8547-87ba21ab0cce' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const OSB = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <img src={arauco} alt='knauf'/></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>OSB</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>Los tableros OSB de ARAUCO se fabrican a partir de hojuelas de madera de alta estabilidad dimensional, orientadas en 3 capas perpendiculares entre si unidas con adhesivos fenólicos y de poliuretano, que permite la industrialización de estructuras y posee una cara texturizada antideslizante que otorga mayor seguridad en la instalación. Recomendado para uso estructural en muros, pisos, techos y cierres perimetrales. Canto de color azul. Resistencia estructural. Durable. Fácil y rápido de instalar.</p>
                    <br/>
                    <p>Los tableros OSB son resistentes a la humedad, su contenido es de 5% a 8%, lo que no significa que sean aptos para estar expuestos directamente a la acción del agua. Para su uso exterior, se recomienda proteger los tableros bajo alero o con recubrimientos adecuados para usar a la intemperie. Se recomienda acondicionar el producto en el lugar donde se instalará, para evitar deformaciones. Al instalar los tableros en techos inclinados, se recomienda colocar la superficie texturizada antideslizante hacia arriba. En muros y pisos aumenta la adherencia de los revestimientos de terminación.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Medidas: </span>9,5/11,1/15,1/18,3mm de espesor, 1.22m x 2.44m.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F5v1.png?alt=media&token=3d3ab096-1ae1-4a45-99fc-4c76e12418e4' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Terciado = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <img src={arauco} alt='knauf'/></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>TERCIADO FENÓLICO</h4>
            <p>Se utiliza para encofrados en albañilería, en la fabricación industrial o artesanal de mueblería, instalaciones de interiores y closets, estanterías para equipos electrónicos, puertas, embalajes, cajas, juguetes, muebles de cocina y baños, y otras aplicaciones de ebanistería.</p>
            <br/>
            <p>Cuenta con caras libres de nudos en grados de apariencia. Reducción de pérdidas de material por calidad superior y mayor precisión de los espesores Gran estabilidad dimensional y alta resistencia a la flexión, tracción y compresión Fácil de manipular por su excelente relación de peso- volumen y de baja emisividad, debido al uso de resina con base a fenol-formaldehído.</p>
            <br/>
            <p className='p-cards-comp'><span>Calidad: </span>C+/C, industrial.</p>
            <p className='p-cards-comp'><span>Medidas: </span>2.44m x 1.22m.</p>
            <p className='p-cards-comp'><span>Dimensión: </span>10mm, 14mm y 18mm.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F6v1.png?alt=media&token=0200ba8a-7724-4b8f-ab24-53af864d6b1e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>EUCALIPTO C+/C</h5>
                </div>
            </div>
        </>
    )
}



const Enchapado = ()=>{
    return(
        <>
            <h4 className='title-placas'>MDF ENCHAPADO</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>Tablero de densidad media, producido con fibras de madera seleccionadas de Pino Radiata enchapado en diferentes especies. Los cantos moldurados de TRUPAN Enchapado pueden ser teñidos, barnizados o pintados directamente.</p>
                    <br/>
                    <p>Excelente calidad de chapa. Permite efectuar cortes sin astillamientos ni rajaduras. Permite maquinarlo, atornillarlo, ensamblarlo y pegarlo, igual que la madera natural. Mayor homogeneidad y resistencia. Los cantos moldurados de TRUPAN Enchapado pueden ser teñidos, barnizados o pintados directamente. Su menor peso permite una mejor manipulación de las piezas a trabajar. Menor desgaste de herramientas derivado de la menor resistencia al golpe de corte. Se utilizan en la fabricación de muebles de oficina, de baño y cocina. Decoración de interiores.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tipo: </span>Guatambu, Cerejeira, Jaquetiba.</p>
                    <p className='p-cards-comp'><span>Medida: </span>En 3mm de espesor, 1.83m x 2.60m / 1.60m x 2.10m.</p>
                    <p className='p-cards-comp'>En 16mm y 19mm de espesor, 1.83m x 2.60m.</p>
                    <p className='p-cards-comp'><span>Espesor: </span>3mm, 16mm y 19mm.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F7v1.png?alt=media&token=82a9dcd5-0ddc-488e-8e79-799c3094a5ac' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Tapacantos = ()=>{
    return (
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <img src={rehau} alt='knauf'/></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>TAPACANTOS MELAMÍNICOS Y DE PVC</h4>
            <p>Se utilizan en la fabricación de muebles para evitar que cantos o bordes queden descubiertos con el corte de los tableros. Cumplen una función decorativa y protegen los tableros de los impactos y humedad.</p>
            <br/>
            <p>Dimensiones:</p>
            <br/>
            <p className='p-cards-comp'><span>PVC:</span> 0,45mm x 22mm / 0,45mm x 45mm</p>
            <p className='p-cards-comp'><span>MELAMINICOS:</span> 22mm y 50mm.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F8v1.png?alt=media&token=55c5be11-3804-4eed-9eb3-288961f17dae' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F8v2.png?alt=media&token=55634653-c747-4ed7-837a-380a17442d07' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Revestimientos = ()=>{
    return(
        <>
            <h4 className='title-placas'>REVESTIMIENTO</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p>En Lumadi Maderas contamos con Revestimiento de PVC y Muropanel.</p>
                    <p>El revestimiento de PVC es ideal para uso residencial, hospitales, escuelas, supermercados, estaciones de servicio, laboratorios, bancos, farmacias, frigoríficos, salones de fiestas, vestuarios, plantas elaboradoras de alimentos, industrias, oficinas, aeropuertos, etc.</p>
                    <br/>
                    <ul>
                        <li>Resistente a la acción de hongos, bacterias, insectos y roedores.</li>
                        <li>Resistencia, rigidez y dureza mecánica elevadas.</li>
                        <li>Rango de temperatura de trabajo de -15ºC a 60ºC.</li>
                        <li>Autoextinguible. No propaga la llama.</li>
                        <li>Vida útil ilimitada.</li>
                        <li>Fácil instalación.</li>
                        <li>Impermeable.</li>
                        <li>No requiere mantenimiento.</li>
                        <li>Material de muy baja propagación de llama.</li>
                    </ul>
                    <br/>
                    <p>Los paneles de PVC son una perfecta alternativa para colocar sobre paredes imperfectas o manchadas por la humedad. Son livianos y versátiles. Actúan como aislante térmico, eléctrico y acústico. Además son reciclables. Es ideal para uso residencial, hoteles, restaurantes, aeropuertos, estaciones de servicio, supermercados, escuelas, fábricas, hospitales, oficinas, etc.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F9v1.jpg?alt=media&token=8d1001c5-2116-4c7a-8324-37a115df76ad' loading='lazy'/>
                        </div>
                    </div>
                    <p className='p-cards-comp'>Revestimiento PVC blanco o color</p>
                    <p className='p-cards-comp'>Medidas: 5x250x2950mm.</p>
                </div>
            </div>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F9v2.png?alt=media&token=4b39dcc2-7aed-4995-8d0d-90e6472bc282' loading='lazy'/>
            <p>Medidas: largo 2.20m.</p>
            <br/>
            <p>El Revestimiento Muropanel es una solución ideal para crear o renovar ambientes. Instalación rápida, limpia y de simple mantenimiento. Una amplia gama de diseños adaptables a todo tipo de estilo. Los paneles se presentan laminados en su cara expuesta, una vez instalados no necesitan ningún tipo de acabado ni retrabajo final. Cada tablero ofrece longitudinalmente los lados machihembrados para una muy simple, fácil y rápida instalación, debiendo cada tabla fijarse a un bastidor. También se presentan molduras foliadas en los mismos diseños que los paneles para resolver cada remate o terminación de los revestimientos.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F9v3.png?alt=media&token=13e38e51-36ed-4928-b25d-440fca3de602' loading='lazy'/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F9v4.png?alt=media&token=8aea848e-226e-4415-8b99-05a7e3a59dd7' loading='lazy'/>
            <br/>
            <p>Tambien se pueden utilizar como revestimiento</p>
            <br/>
            <ul>
                <li>Placas de yeso. Puede encontrarse en la solapa de “YESO PROYECTADO”</li>
                <li>Machimbre ½” x 4”. Puede encontrarse en la solapa de “TECHOS”</li>
                <li>Fondo Faplac de 5.5mm. Puede encontrarse en esta misma solapa en la línea de “FAPLAC FONDOS”</li>
            </ul>
        </>
    )
}



const Tableros = ()=>{
    return (
        <>
            <h4 className='title-placas'>TABLEROS</h4>
            <p>Un tablero es una pieza compuesta de varias tablas a modo de plancha. Se utiliza para soporte de los tableros de mobiliario, en estantería de ordenación, embalaje, material de relleno de elementos de madera, como puertas o material de construcción.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F10v1.jpg?alt=media&token=c61374cc-f5fe-46fa-af35-2f124b6d7ff3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TABLERO PINO FINGER</h5>
                    <p className='p-cards-comp'><span>Medidas: </span>20mm x 1.20 x 3.00m / 30mm x 1.20 x 3.00m / 40mm x120 x3.00m.</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F10v2.jpg?alt=media&token=3e31ca32-b9db-4259-8720-1e9721b94e0b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TABLERO PINO FINGER (ESTANTES)</h5>
                    <p className='p-cards-comp'><span>Medidas: </span>20mm x 300mm/400m x 750/900/1150/2000mm / 30mm x 300mm/400m x 750/900/1150/2000mm</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F10v3.jpg?alt=media&token=05873c27-4362-4999-9244-a6d1525a72b4' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TABLERO DE SALIGNA / EUCA FINGER</h5>
                    <p className='p-cards-comp'><span>Medidas: </span>20 x 1200 x 3000mm / 30 x 1200 x 3000mm / 40 x 1200 x 3000mm / 40 x 300 x 6000mm / 40 x 600 x 6000mm</p>
                </div>
            </div>
        </>
    )
}


const Formica = ()=>{
    return(
        <>
            <h4 className='title-placas'>FORMICA TS EXTERIOR</h4>
            <p>El TS Exterior es un equipo compacto, de alta densidad, estable, no - poroso, químicamente inerte, que ofrece una amplia gama de aplicaciones en fachadas exteriores. Al ser propietario de una película protectora contra la luz ultravioleta "los rayos UV, el TS exterior es resistente a la intemperie y a temperaturas más elevadas. Puede quedar expuesto a la acción de la luz solar y los fenómenos atmosféricos; como lluvia (siempre que el material no quede sumergido), granizo, acción de descarga de los gases de lluvia ácida, viento y salinidad. Estos fenómenos no causan ningún efecto sobre la superficie y sobre el sustrato del producto. Las variaciones naturales en humedad (húmedo o seco), así como variaciones extremas de temperatura (-20 ° C a 80 ° C), no pongan en peligro las propiedades del exterior TS. Es fácil de limpiar y no sufre corrosión.</p>
            <br/>
            <p>El laminado Ts es exterior anti-graffiti , no permite la adhesión de diversos tipos de pinturas, utilizados principalmente por etiquetadores y pintada, que se puede eliminar fácilmente por medio de disolvente utilizado para la dilución y limpieza, tales como; acetona; agua raz, thinner-</p>
            <p>La resistencia superficial permite la remoción varias veces sin que ocurra algún tipo de cambio en el color o la textura del producto.</p>
            <br/>
            <p>Ventajas de usar el TS Exterior de Formica® en fachadas de edificios y construcciones:</p>
            <br/>
            <ul>
                <li>Resistencia a la intemperie y la luz del sol;</li>
                <li>No sufre corrosión;</li>
                <li> Fácil limpieza y mantenimiento;</li>
                <li>Resistencia a las oscilaciones de temperatura;</li>
                <li>Gran disponibilidad de patrones (madereros, lisos y piedras)</li>
                <li>Resistencia al impacto;</li>
                <li>Antiestático;</li>
                <li>No contiene amianto ni metales en su composición;</li>
                <li>Antigüedad;</li>
                <li>Versátil en la combinación con productos para cierres externos</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>8 y 10mm de espesor - 1.22x3.07m/1.22x2.50m</p>
            <p className='p-cards-comp'><span>Colores: </span>consultar por los colores disponibles.</p>
            <br/>

            <h4 className='title-placas'>FORMICA MESADAS</h4>
            <p className='p-cards-comp'>El TS de FORMICA® es el laminado compacto, robusto, con una o ambas caras decorativas, perfectos para aplicaciones en áreas húmedas, en donde los muebles tienen mayor contacto y exposición al agua, como en cuartos de baño, lavabos y cocinas. Se utilizan mucho en áreas que exijan durabilidad, resistencia superior a impactos y en donde se exija belleza, diseño y resistencia, como cafeterías, restaurantes, estaciones de trabajo y oficinas. las extremidades y bordes del TS pueden quedar aparentes y recibir tratamientos especiales de corte lo que da formas diferenciadas e innovadoras al proyecto.</p>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>10y20mm de espesor / 1.25x2.51m / 1.25x3.08m.</p>
            <br/>

            <h4 className='title-placas'>FORMIWALL</h4>
            <p className='p-cards-comp'>El Formiwall es el laminado decorativo de alta presión desarrollada específicamente para paredes de revestimiento. Garantiza la practicidad, pudiendo ser aplicado directamente sobre azulejos, concretos, etc. Es la opción ideal para paredes internas de shoppings, hospitales, restaurantes, bares, residencias y obras de construcción civil en general.</p>
            <p className='p-cards-comp'>Para facilitar la aplicación, Formiwall es ideal para las renovaciones! Por ser práctico y de rápida instalación, el producto puede ser colocado sobre acabados ya existentes. Por lo tanto, es el más recomendado para aquellos que buscan una solución que evite el "rompecabezas" y, consecuentemente, la acumulación indeseada de escombros.</p>
            <br/>
            <p className='p-cards-comp'>Características especiales:</p>
            <ul>
                <li>Estabilidad de colores y patrones;</li>
                <li>Resistencia a impactos;</li>
                <li>Resistencia a las manchas causadas por productos químicos comunes, de uso doméstico (amoniaco, detergente, tinta, vinagre, mercurocromo, etc.);</li>
                <li>Antialérgico, pues su superficie lisa y no porosa no permite la proliferación de hongos y bacterias;</li>
                <li>Su peso reducido (1,7 Kg / m2) produce una carga menor sobre las estructuras en comparación con los revestimientos tradicionales;</li>
                <li>Fácil aplicación y mantenimiento;</li>
                <li>Excelente acabado.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>1.3mm de espesor / 1.25x2.51m / 1.25x2.80m / 1.25x3.08m.</p>
            <br/>

            <h4 className='title-placas'>LAMINADOS</h4>
            <p className='p-cards-comp'>Laminado decorativo de alta presión, desarrollado mediante tecnología avanzada, para revestimientos horizontales y verticales. Debido a su resistencia superior a impactos, durabilidad, belleza y diseño, se utiliza mucho en restaurantes, centros comerciales, residencias, bares, oficinas y obras de construcción civil en general. También se puede utilizar en el revestimiento de puertas y paneles divisorios. Posee gran resistencia al desgaste, al calor, al impacto y a manchas por lo que es un producto práctico y duradero.</p>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>0.6 y 0.8mm de espesor / 1.25x3.06m.</p>

            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>UNICOLORES</h4>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v1.jpg?alt=media&token=078ce5bf-c37f-448c-a168-c57de1b49735' loading='lazy'/>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MADERADOS</h4>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v2.jpg?alt=media&token=8b6499ed-0f32-4b8f-90d6-e153de0a7a9d' loading='lazy'/>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>PIEDRA Y FANTASÍA</h4>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v3.jpg?alt=media&token=9343c03e-46a3-42b8-a9fd-46a8bf9438ed' loading='lazy'/>

            <hr/>
            <hr/>
            <hr/>
            <h4 className='title-placas'>FORMICA REAL COLOR</h4>
            <p className='p-cards-comp'>Formica color real es el futuro del laminado, con lo que las posibilidades de un producto innovador con una garantía de la marca Formica. Es un producto unicolor, con cara decorativa y miolo del mismo color, que dispensa el uso de la Cinta de Borda, presentando un mejor acabado. Ideal para diseño distintivo móvil y el concepto monolítico, Formica color real tiene más luz y mayor flexibilidad, otorgada por la adición de resina especialmente formulada para ello. Fácil de cortar y mecanizar, el producto aumenta la velocidad en la ejecución del proyecto, optimizando el tiempo del profesional.</p>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>0.8; 1.3; 4.0; 6.0; 10.0mm de espesor / 3.08x1.25m.</p>
            <br/>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v4.jpg?alt=media&token=4d16ff10-31e5-4af4-b3c4-a65969f04a75' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L101 ROJO CARDENAL</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v5.jpg?alt=media&token=858c46ab-caae-4c7e-bfdd-d69fee6ea4b1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L121 NEGRO</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v6.jpg?alt=media&token=e91259fe-0010-4f76-916d-a9319746180e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L151 GRIS</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v7.jpg?alt=media&token=12288b23-3e42-4341-92ef-3e7d648554ec' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L515 BLANCO REAL</h5>
                </div>
            </div>

            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v8.jpg?alt=media&token=994644b4-9376-4466-a6f5-29e4b10d0d78' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L523 NUEVO CROMO REAL</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v9.jpg?alt=media&token=0bba8df8-6ad5-436a-9059-166d7530fe4e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L551 NARANJA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v10.jpg?alt=media&token=940774e3-202a-4584-8bc7-3e67742eb558' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L552 PURPURA</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F11v11.jpg?alt=media&token=a35dbc07-ec5a-4981-8303-aac2711b9456' loading='lazy'/>
                        </div>
                    </div>
                    <h5>L562 CORDA</h5>
                </div>
            </div>
            <br/>

            <h4 className='title-placas'>NATURAL WOOD</h4>
            <p className='p-cards-comp'>Con la textura de la madera, Natural Wood es mucho más simple y rápido de aplicar, además de la mayor durabilidad con relación a las láminas naturales. No deja marcas de manos y tiene mayor resistencia a las manchas. Es la línea ideal para quien quiere tener en sus proyectos belleza y practicidad.</p>
            <br/>
            <p className='p-cards-comp'><span>Medidas: </span>0.6mm espesor / 1.30 x 2.80m.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Mayor facilidad en el proceso de pegado, pues no forma burbujas ni rajaduras. Se puede utilizar pegamento de contacto a base de solvente y de agua de FORMICA®, o demás adhesivos normalmente utilizados para la aplicación de laminados de alta presión FORMICA®.</li>
                <li>Mantiene el color uniforme y prescinde del acabado con barniz, selladora o lija.</li>
            </ul>
            <div className='cards-placas-container'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v1.jpg?alt=media&token=d0d2621f-ccef-4248-960b-d5700b37987f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N700 BLACK WOOD</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v2.jpg?alt=media&token=4ce1fc3d-2323-4e1a-9644-dc7c8683dfb3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N701 DAKAR WOOD</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v3.jpg?alt=media&token=a508f46a-6b67-4f57-bb7a-c70393b134ee' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N703 LANCASTER WOOD</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v4.jpg?alt=media&token=6403b7d5-5081-4b31-91da-3753a9148a11' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N704 CASTLE WOOD</h5>
                </div>
            </div>

            <div className='cards-placas-container space'>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v5.jpg?alt=media&token=28ffbf28-ebcf-4bbd-ae98-fd7290712742' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N705 COCONUT</h5>
                </div>
                <div className='card-placas-20'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F14v6.jpg?alt=media&token=4b7c574f-f63b-4418-85f5-17a35cadf7cc' loading='lazy'/>
                        </div>
                    </div>
                    <h5>N706 ETERNIT WALNUT</h5>
                </div>
            </div>
        </>
    )

}



const Pizarron = ()=>{
    return (
        <>
            <h4 className='title-placas'>PIZARRÓN</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Se trata de un Laminado de alta resistencia al desgaste por abrasión y con un acabado superficial apropiado para ser escrito sea con fibra, permitiendo una fácil limpieza y borrado y libre de mantenimiento. El uso de la pizarra magnética permite desarrollar las capacidades del niño a través del juego, considerando sus intereses e incentivando la motivación hacia el aprendizaje. Está muy bien posicionado como elemento didáctico para establecimientos escolares, dada su facilidad de escritura y borrado sin dejar rastros. Esta bondad se logra por medio de un proceso de prensado con temperatura y alta presión, otorgando un alisado superficial superior que evita el alojamiento de restos de tinta.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Color: </span>blanco brillante.</p>
                    <p className='p-cards-comp'><span>Medidas: </span>10mm de espesor / 1.22x3.06m.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F12v1.jpg?alt=media&token=61b3df9b-ace5-4af6-a360-f0da8e8803e3' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Paner = ()=>{
    return (
        <>
            <h4 className='title-placas'>PANEL RANULADO EXPOSITOR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Panel de madera ranurado, para el equipamiento de instalaciones comerciales, para la sujeción de elementos de exposición, tales como ganchos, barras, soportes, estantes, etc. Ideal para utilizar en decoraciones de ambientes, montaje o reforma de negocios y puntos de venta, entre otros. Es un producto que posee amplios beneficios, entre los cuales se pueden contar su bajo costo, practicidad y facilidad de colocación Por sus características, representa una solución a las necesidades de los profesionales en diseño y arquitectura.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Color: </span>blanco</p>
                    <p className='p-cards-comp'><span>Medidas: </span>18mm de espesor / 1.85x2.75m.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Accesorios: </span>consultar por modelos disponibles.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F13v1.jpg?alt=media&token=9483420f-34b2-43dc-b3f9-735761411bf4' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPlacas%2F13v2.jpg?alt=media&token=285e4014-4d66-4416-b6f9-65815fbfbe6a' loading='lazy'/>
        </>
    )
}