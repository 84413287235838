import React from 'react';
import './E404.css';
import img404 from '../../Images/404.jpg';
import {NavLink} from 'react-router-dom';

const E404 = () => {
  return (
    <div className='E404'>
      <h2>404</h2>
      <h3>Página no encontrada</h3>
      <p>La página especificada no existe en este sitio web, por favor, revisa todos los errores de ortografía en la URL e intenta nuevamente.</p>
      <img src={img404} alt='Error 404'/>
      <NavLink to='/'>Ir al Inicio</NavLink>
    </div>
  )
}

export default E404;
